var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-textarea",
    _vm._b(
      {
        class: { "fd-readonly": _vm.$attrs.readonly },
        attrs: {
          outlined: "",
          "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
          value: _vm.value,
          disabled: _vm.$attrs.disabled || _vm.disabled,
          readonly: _vm.$attrs.readonly
        },
        on: {
          input: function(v) {
            return _vm.$emit("input", v)
          }
        }
      },
      "v-textarea",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }