

































































































import {mapMutations, mapState, mapActions} from "vuex";
import i18n from '../i18n'
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],

    name: 'fd-Modifier-Value-Existing',

    components: {
        "fd-back-button": () => import('@fd/lib/vue/components/BackButton.vue')
    },

    data: function () { return {
        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are conducting any screen
        // wide actions.
        processing: false,

        // The following will control whether or not the save button shows the processing/loading indicator
        saving: false,

        //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
        breadcrumbs: [
            {
                text: 'Modifiers',
                disabled: false,
                to: '/modifiers',
            },
            {
                text: this.$t("loading-dot-dot-dot"),
                disabled: true,
            },
        ],

        slidein:false,

        modifiervalue : {
            name: "",
            value: "",
            enabled: ""
        },

        rules: {
            required: value => !!value || i18n.t('common.rule-required'),
            numbersonly: value => !(value && isNaN(value)) || i18n.t('common.rule-numbers-only')
        },

    };},

    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.save();
        },
        // Method used in conjunction with the Save button.
        async save() {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            this.saving = true;

            try {
                await this.updateModifierValue({
                    // id: this.$route.params.modifiervalueID,
                    // name: this.modifiervalue.name,
                    // value: this.modifiervalue.value,
                    // modifierID: this.modifiervalue.modifierID,
                    // enabled: this.modifiervalue.enabled
                    ...this.modifiervalue,
                });
                this.$router.push(`/modifiers/${this.modifiervalue.modifierID}/`);
            } catch (error) {
                this.inlineMessage.message = this.$t("modifiers.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.saving = false;
            }
        },
        // the following works with the delete "Action" button in the Datatable.
        async deleteItem() {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.deleteModifierValue({ id: this.$route.params.modifiervalueID, name: this.modifiervalue.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.$router.push(`/modifiers/${this.modifiervalue.modifierID}/`);
            }
        },

        // Method used in conjunction with the Cancel button.
        cancel() {
            // TODO: Should this roll back state rather than rely on requerying?
            this.$router.push(`/modifiers/${this.modifiervalue.modifierID}/`);
        },
        ...mapMutations({
            setModifierValue: 'SET_MODIFIER_VALUE',
            notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
        }),
        ...mapActions({
            loadModifierValue: 'LOAD_MODIFIER_VALUE',
            updateModifierValue: 'UPDATE_MODIFIER_VALUE',
            deleteModifierValue: 'DELETE_MODIFIER_VALUE'
        })
    },

    created: async function () {
        this.processing = true;

        // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
        setInterval(() => {
            this.slidein = true;
        }, 100);

        try {
            await this.loadModifierValue(this.$route.params.modifiervalueID);
            var modifiervalue = this.$store.state.modifiervalues.fullList.find(x => x.id == this.$route.params.modifiervalueID);

            this.notifyNewBreadcrumb({
                text: modifiervalue.name,
                to: `/modifiervalues/${this.$route.params.modifiervalueID}`
            });
            this.modifiervalue = {...modifiervalue};
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }

    }
})
