import Vue, { ComponentOptions, VueConstructor } from "vue";
import {
  DefaultMethods,
  ArrayPropsDefinition,
  RecordPropsDefinition,
  DataDef
} from "vue/types/options";
import { ExtendedVue, CombinedVueInstance } from "vue/types/vue";

type UnionToIntersection<U> = (U extends any
? (k: U) => void
: never) extends (k: infer I) => void
  ? I
  : never;

type ExtractInstance<T> = T extends VueConstructor<infer V>
  ? V
  : T extends ComponentOptions<infer V>
  ? V
  : never;

// These handle parsing out any mixins embedded in the options so that we can get all of the
// publicly declared data, methods and other things
export type VueMixin = VueConstructor | ComponentOptions<never>;
export type ExtendedMixedVue<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  Props
> = Mixins extends (infer T)[]
  ? ExtendedVue<
      UnionToIntersection<ExtractInstance<T>> & V,
      Data & ExtendedData,
      Methods,
      Computed,
      Props
    >
  : never;
export type CombinedMixedVueInstance<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  Props
> = Mixins extends (infer T)[]
  ? CombinedVueInstance<
      UnionToIntersection<ExtractInstance<T>> & V,
      Data & ExtendedData,
      Methods,
      Computed,
      Props
    >
  : never;

// These types are rewrites of the equivalent ThisTypedComponentOptionsWithXxxxxProps types that
// allow us to extend our acceptable parameters and have them automatically show up as data
// properties in TypeScript
export type ExtendedThisTypedComponentOptionsWithArrayProps<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  PropNames extends string
> = object &
  ComponentOptions<
    V,
    DataDef<Data, Record<PropNames, any>, V>,
    Methods,
    Computed,
    PropNames[],
    Record<PropNames, any>
  > &
  ThisType<
    CombinedMixedVueInstance<
      V,
      ExtendedData,
      Data,
      Mixins,
      Methods,
      Computed,
      Readonly<Record<PropNames, any>>
    >
  > & {
    mixins?: Mixins;
  };
export type ExtendedThisTypedComponentOptionsWithRecordProps<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  Props
> = object &
  ComponentOptions<
    V,
    DataDef<Data, Props, V>,
    Methods,
    Computed,
    RecordPropsDefinition<Props>,
    Props
  > &
  ThisType<
    CombinedMixedVueInstance<V, ExtendedData, Data, Mixins, Methods, Computed, Readonly<Props>>
  > & {
    mixins?: Mixins;
  };

// Baseline extend wrapper with added bonus materials to make for better typing; it also doesn't
// have the downside of losing all context when something is typed incorrectly; the overload
// of the function that looks like this:
//
// extend(options?: ComponentOptions<V>): ExtendedVue<V, {}, {}, {}, {}>;
//
// is the one that causes the screen to break in unexpected ways when you provide a data element
// or method that can't be consumed
function extend<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  PropNames extends string = never
>(
  options?: ExtendedThisTypedComponentOptionsWithArrayProps<
    V,
    ExtendedData,
    Data,
    Mixins,
    Methods,
    Computed,
    PropNames
  >
): ExtendedMixedVue<V, ExtendedData, Data, Mixins, Methods, Computed, Record<PropNames, any>>;
function extend<
  V extends Vue,
  ExtendedData,
  Data,
  Mixins extends VueMixin[],
  Methods,
  Computed,
  Props
>(
  options?: ExtendedThisTypedComponentOptionsWithRecordProps<
    V,
    ExtendedData,
    Data,
    Mixins,
    Methods,
    Computed,
    Props
  >
): ExtendedMixedVue<V, ExtendedData, Data, Mixins, Methods, Computed, Props>;
// function extend<V extends Vue, InstanceData>(options?: ComponentOptions<V> & DataControllerOptions<InstanceData>): ExtendedVue<V, {}, {}, {}, {}>;
function extend<
  V extends Vue,
  ExtendedData,
  Data extends object | ((this: V) => object),
  Mixins extends VueMixin[],
  Methods extends DefaultMethods<V>,
  Computed,
  Props extends
    | ArrayPropsDefinition<Record<string, any>>
    | RecordPropsDefinition<Record<string, any>>
    | undefined
>(
  options?: ComponentOptions<V, Data, Methods, Computed, Props>
): ExtendedMixedVue<V, ExtendedData, Data, Mixins, Methods, Computed, Props> {
  return Vue.extend(options as ComponentOptions<Vue>) as ExtendedMixedVue<
    V,
    ExtendedData,
    Data,
    Mixins,
    Methods,
    Computed,
    Props
  >;
}

const FDVue = {
  options: null,
  extend
};
export default (FDVue as unknown) as {
  readonly options: ComponentOptions<Vue>;
  extend: typeof extend;
};

export function registerOptions(options: ComponentOptions<Vue>) {
  (FDVue as any).options = options;
}

import VueCompositionApi, {
  defineComponent,
  reactive,
  ref,
  computed,
  watchEffect,
  watch,
  onMounted,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  toRefs,
  provide,
  inject,
  Ref,
  ComputedRef
} from "@vue/composition-api";
export {
  defineComponent,
  reactive,
  ref,
  computed,
  watchEffect,
  watch,
  onMounted,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  toRefs,
  provide,
  inject,
  Ref,
  ComputedRef
};
Vue.use(VueCompositionApi);
