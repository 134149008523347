var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "add-fab-button-primary",
      attrs: {
        "data-cy": _vm.cy,
        absolute: "",
        bottom: "",
        left: "",
        fab: "",
        elevation: "5",
        depressed: _vm.disabled,
        disabled: _vm.disabled,
        color: "new"
      },
      on: {
        click: function($event) {
          return _vm.$props.action()
        }
      }
    },
    [_c("v-icon", [_vm._v("mdi-plus")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }