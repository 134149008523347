var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.inlineMessage.message) + "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ],
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "add-fab-button-primary",
                          attrs: {
                            dark: "",
                            absolute: "",
                            bottom: "",
                            left: "",
                            fab: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.openNewDialog()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("common.search"),
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.tablesearch,
                  callback: function($$v) {
                    _vm.tablesearch = $$v
                  },
                  expression: "tablesearch"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:name.hide-when-extra-small",
                arg: "name",
                modifiers: { "hide-when-extra-small": true }
              },
              { name: "fd-column", rawName: "v-fd-column:alias", arg: "alias" },
              {
                name: "fd-column",
                rawName: "v-fd-column:description.hide-when-small",
                arg: "description",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:enabled.hide-when-extra-small",
                arg: "enabled",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:actions.no-sort.hide-when-extra-small",
                arg: "actions",
                modifiers: { "no-sort": true, "hide-when-extra-small": true }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: function(item) {
                  return _vm.$router.push("/owners/" + item.id)
                },
                expression: "item => $router.push(`/owners/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            staticClass: "fd-actions-table fd-enabling-table",
            attrs: {
              items: _vm.owners,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["alias"],
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.enabled",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.enabled, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipEnabled(item)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: "/owners/" + item.id
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                                    mdi-pencil\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteTableItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    mdi-delete\n                                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }