var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "valueDiv",
      staticStyle: { cursor: "text" },
      on: { click: _vm.onValueClicked }
    },
    [
      _c("div", { staticClass: "inline-editable-column-field-indicator" }, [
        _vm._v(_vm._s(_vm.displayValue))
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "hide-overlay": "",
            "content-class": "fd-supress-unneeded-v-dialog-width"
          },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "v-form",
            { ref: "form", on: { submit: _vm.onSubmit } },
            [
              _c(
                "v-card",
                { style: _vm.dialogStyle },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0 pb-0" },
                    [
                      _c(
                        "v-container",
                        { staticStyle: { padding: "0px" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-text-field", {
                                    ref: "textbox",
                                    attrs: {
                                      label: _vm.label,
                                      rules: _vm.rules,
                                      "single-line": "",
                                      autofocus: "",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.interimValue,
                                      callback: function($$v) {
                                        _vm.interimValue = $$v
                                      },
                                      expression: "interimValue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "fd-inline-edit-dialog",
                          attrs: { text: "" },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("common.cancel")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "fd-inline-edit-dialog",
                          attrs: { text: "" },
                          on: { click: _vm.applyUpdates }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }