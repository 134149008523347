


















































































import Vue from "vue";
import {mapState, mapMutations, mapActions} from "vuex";
import i18n from '../../i18n';
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";

const OwnerNewDialog = Vue.extend ( {
    name: 'fd-owner-new-dialog',
    mixins: [dialogSupport],

    data: () => ({
        owner : {
            name: "",
            alias: "",
            description: ""
        },

        rules: {
            required: value => !!value || i18n.t('common.rule-required'),
            aliascounter: value => !value || value.length <= 20 || i18n.t('common.rule-alias-counter', '20'),
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following is responsible for the inline messages that may be presented to the user.
        // If the message property is null then no message will be shown to the user.
        // The type can be one of the following strings:
        // 'success'
        // 'info'
        // 'warning'
        // 'error'
        inlineMessage: {
            message: null,
            type: 'error'
        },
    }),

    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.saveDialog();
        },

        // Method used in conjunction with the Cancel dialog.
        cancelDialog() {
            this.closeDialog!(false);
        },

        //Method used in conjunction with new view dialog.
        async saveDialog () {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            try {
                await this.addOwner({
                    name: this.owner.name,
                    alias: this.owner.alias,
                    description: this.owner.description,
                    enabled: true
                });
                this.closeDialog!(true);
            } catch (error) {
                this.inlineMessage.message = this.$t("owners.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        ...mapActions({
            addOwner: 'ADD_OWNER'
        })
    }

});

export default OwnerNewDialog;

export async function createNewOwner(): Promise<boolean> {
    let dialog = createDialog(OwnerNewDialog);
    return await dialog.showDialog!();
}
