var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticClass: "d-none d-sm-flex",
                                    class: [
                                      _vm.processing
                                        ? "breadcrumb-processing-opacity"
                                        : "",
                                      "pl-3"
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("fd-back-button", {
                                    attrs: {
                                      route: function(item) {
                                        return _vm.$router.push("/modifiers/")
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { "icons-and-text": "" },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            { key: "details", ref: "tab" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("common.details")) +
                                  "\n                            "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            { key: "details" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pl-3 pr-3 pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("common.name"),
                                          disabled: _vm.processing,
                                          rules: [_vm.rules.required]
                                        },
                                        model: {
                                          value: _vm.modifier.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.modifier, "name", $$v)
                                          },
                                          expression: "modifier.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          label: _vm.$t("common.description"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.modifier.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.modifier,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "modifier.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t("common.enabled"),
                                          disabled: _vm.processing
                                        },
                                        model: {
                                          value: _vm.modifier.enabled,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.modifier,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression: "modifier.enabled"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.inlineMessage.message
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: _vm.inlineMessage.type
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.inlineMessage.message
                                                  ) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-0 mr-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                link: "",
                                                disabled: _vm.processing,
                                                color: "error",
                                                outlined: ""
                                              },
                                              on: { click: _vm.deleteItem }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.delete"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                link: "",
                                                disabled: _vm.processing
                                              },
                                              on: { click: _vm.cancel }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.cancel"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.saving,
                                                disabled: _vm.processing
                                              },
                                              on: { click: _vm.save }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.save"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            {
                              key: "values",
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected("values")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t("modifiers.values.sub-menu-title")
                                  ) +
                                  "\n                            "
                              ),
                              _c("v-icon", [_vm._v("fas fa-list")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            { key: "values" },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openNewDialog()
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "modifiers.values.sub-menu-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearch,
                                              callback: function($$v) {
                                                _vm.tablesearch = $$v
                                              },
                                              expression: "tablesearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:value",
                                            value: _vm.$t("common.value"),
                                            expression: "$t('common.value')",
                                            arg: "value"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:enabled.hide-when-extra-small",
                                            arg: "enabled",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/modifiervalues/" + item.id
                                              )
                                            },
                                            expression:
                                              "item => $router.push(`/modifiervalues/${item.id}`)",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-enabling-table",
                                        attrs: {
                                          items: _vm.modifiervalues,
                                          search: _vm.tablesearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.enabled",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: item.enabled,
                                                    disabled: _vm.processing
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.flipEnabled(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/modifiervalues/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                mdi-pencil\n                                                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { right: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteTableItem(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                mdi-delete\n                                                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("fd-modifier-value-new-dialog", {
        attrs: {
          show: _vm.showNewDialog,
          modifierID: _vm.$route.params.modifierID
        },
        on: {
          "update:show": function($event) {
            _vm.showNewDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }