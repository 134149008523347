






















































































































import {mapMutations, mapState, mapActions} from "vuex";
import {filterByTags} from "../services/taggableItems";
import { createNewOwner } from "./components/OwnerNewDialog.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],    

    name: 'fd-Owners',

    directives: {
      fdColumn: FDColumnDirective,
      fdRowNavigate: FDRowNavigateDirective
    },

    data: () => ({

        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,


        // The following is used to define the breadcrumbs navigation for the view.
        breadcrumbs: [
            {
                text: 'Owners',
                disabled: true,
            },
        ],

    }),

    computed: {
        owners () { return this.$store.state.owners.fullList; },

        // Used to store the value of the search bar in the store so that if you go to the existing screen and back,
        // that the users filtering setup they have chosen will be preserved.
        tablesearch: {
            get () {
                return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
            },
            set (val) {
                this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
            },
        },
    },

    methods: {
        async openNewDialog() {
            await createNewOwner();
        },

        // the following works with the delete "Action" button in the Datatable.
        async deleteTableItem (item) {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.deleteOwner({ id: item.id, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        async flipEnabled (item) {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.updateOwner({ id: item.id, enabled: !item.enabled, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        ...mapMutations({
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
            setFilteringContext: 'SET_FILTERING_CONTEXT'
        }),
        ...mapActions({
            loadOwners: 'LOAD_OWNERS',
            updateOwner: 'UPDATE_OWNER',
            deleteOwner: 'DELETE_OWNER'
        })
    },

    created: async function () {

        // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
        // a sub screen of something that is currently filtered by their choices that those choices will be
        // preserved as they move between the two screens.
        this.setFilteringContext({
            context: "owners",
            parentalContext: null,
            searchStringForFiltering: "",
            tagsForFiltering: [],
            suppliersForFiltering: []
        });

        this.notifyNewBreadcrumb({
            text: "Owners",
            to: "/owners",
            resetHistory: true
        });
        this.processing = true;
        try {
            await this.loadOwners();
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }
    }
})
