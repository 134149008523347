













































































import {mapState, mapMutations, mapActions} from "vuex";
import i18n from '../../i18n'

export default {
    name: 'fd-modifier-new-dialog',

    // TODO: Implement show property as mixin? Figure this out as we explore other optimizations
    props: ['show'],

    mounted () {
        // Bind the inbound value of the 'show' property to our own handlers so we can act
        // when we're asked to show and hide ourselves
        // TODO: Consider a binding that exposes a callable method; toggling data like this seems to be weird, although it's what I saw online
        this.onShowPropChanged(this.value);
    },

    data: () => ({
        dialogVisible: false,

        modifier : {
            name: "",
            description: "",
            enabled: true
        },

        rules: {
            required: value => !!value || i18n.t('common.rule-required')
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following will control whether or not the save button shows the processing/loading indicator
        saving: false,

        // The following is responsible for the inline messages that may be presented to the user.
        // If the message property is null then no message will be shown to the user.
        // The type can be one of the following strings:
        // 'success'
        // 'info'
        // 'warning'
        // 'error'
        inlineMessage: {
            message: null,
            type: 'error'
        },
    }),

    watch: {
        // We need to watch for when the "show" property is changed by the parent view.
        show (val) { this.onShowPropChanged(val); }
    },

    methods: {
        onShowPropChanged(value) {
            this.dialogVisible = value;
        },
        onSubmit(e) {
            e.preventDefault();
            this.saveDialog();
        },

        closeDialog () {
            this.$refs.form.reset();
            this.inlineMessage.message = null;
            this.dialogVisible = false;
            this.$emit("update:show", false);
        },

        // Method used in conjunction with the Cancel dialog.
        cancelDialog() {
            this.closeDialog();
        },

        //Method used in conjunction with new view dialog.
        async saveDialog() {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            this.saving = true;
            try {
                await this.addModifier(this.modifier);
                this.closeDialog();
            } catch (error) {
                this.inlineMessage.message = this.$t("modifiers.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.saving = false;
            }
        },
        ...mapActions({
            addModifier: 'ADD_MODIFIER'
        })
    }
}
