






























































































































import {mapMutations, mapState, mapActions} from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],

    name: 'fd-Modifiers',

    components: {
        "fd-modifier-new-dialog": () => import('./components/ModifierNewDialog.vue'),
    },

    directives: {
        fdColumn: FDColumnDirective,
        fdRowNavigate: FDRowNavigateDirective
    },

    data: () => ({
        // The following tracks the current width of the browser window. It works in conjunction with a EventListener
        // setup in the "created" hook.
        windowWidth:0,

        showNewDialog: false,

        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following is used to define the breadcrumbs navigation for the view.
        breadcrumbs: [
            {
                text: 'Modifiers',
                disabled: true,
            },
        ],
    }),

    computed: {
        modifiers () { return this.$store.state.modifiers.fullList; },

        tablesearch: {
            get () {
                return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
            },
            set (val) {
                this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
            },
        },
    },

    methods: {
        openNewDialog () {
            this.showNewDialog = true;
        },

        // the following works with the delete "Action" button in the Datatable.
        async deleteTableItem (item) {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.deleteModifier({ id: item.id, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        async flipEnabled (item) {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.updateModifier({ id: item.id, enabled: !item.enabled, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        ...mapMutations({
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
            setFilteringContext: 'SET_FILTERING_CONTEXT'
        }),
        ...mapActions({
            loadModifiers: 'LOAD_MODIFIERS',
            updateModifier: 'UPDATE_MODIFIER',
            deleteModifier: 'DELETE_MODIFIER'
        })
    },

    created: async function () {
        // Listen to the "resize" even for the browser so we always know the width and can use that
        // knowledge for various responsive layout reasons.
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        });
        this.windowWidth = window.innerWidth;

        // Set the context for the Tags Filtering in the store so that if the user navigates to a screen that is
        // a sub screen of something that is currently filtered by these tags that the tags selected will be
        // preserved as they move between the two screens.
        this.setFilteringContext({
            context: "modifiers",
            parentalContext: null,
            searchStringForFiltering: "",
            tagsForFiltering: [],
            suppliersForFiltering: []
        });

        this.notifyNewBreadcrumb({
            text: "Modifiers",
            to: "/modifiers",
            resetHistory: true
        });
        this.processing = true;
        try {
            await this.loadModifiers();
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }
    }
})
