var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fd-image-editor", {
        attrs: { name: "Pintura Test Image" },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("h1", [_vm._v("Pintura Image Editor")]),
      _vm._v(" "),
      _c("fd-add-file-button", { on: { change: _vm.addFile } }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [_c("h2", [_vm._v("Inline")])]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-btn", { on: { click: _vm.inlineButtonClick } }, [
                _vm._v("Process")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "70vh" } },
        [
          _c(
            "PinturaEditor",
            _vm._b(
              {
                ref: "inline",
                attrs: { src: _vm.inlineSrc },
                on: {
                  "pintura:load": function($event) {
                    return _vm.handleInlineLoad($event)
                  },
                  "pintura:process": function($event) {
                    return _vm.handleInlineProcess($event)
                  }
                }
              },
              "PinturaEditor",
              _vm.editorProps,
              false
            )
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.inlineResult
        ? _c("p", [_c("img", { attrs: { src: _vm.inlineResult, alt: "" } })])
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v("Modal")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c(
            "v-btn",
            {
              on: {
                click: function($event) {
                  _vm.modalVisible = true
                }
              }
            },
            [_vm._v("Open editor")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.modalVisible
        ? _c(
            "PinturaEditorModal",
            _vm._b(
              {
                attrs: { src: _vm.modalSrc },
                on: {
                  "pintura:hide": function($event) {
                    _vm.modalVisible = false
                  },
                  "pintura:show": function($event) {
                    return _vm.handleModalShow()
                  },
                  "pintura:close": function($event) {
                    return _vm.handleModalClose()
                  },
                  "pintura:load": function($event) {
                    return _vm.handleModalLoad($event)
                  },
                  "pintura:process": function($event) {
                    return _vm.handleModalProcess($event)
                  }
                }
              },
              "PinturaEditorModal",
              _vm.editorProps,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.modalResult
        ? _c("p", [_c("img", { attrs: { src: _vm.modalResult, alt: "" } })])
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v("Overlay")]),
      _vm._v(" "),
      _c(
        "p",
        [
          !_vm.overlayVisible
            ? _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.overlayVisible = true
                    }
                  }
                },
                [_vm._v("Edit image")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.overlayVisible
            ? _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      _vm.overlayVisible = false
                    }
                  }
                },
                [_vm._v("Close editor")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.overlayVisible
        ? _c("p", [
            _c("img", {
              attrs: {
                src: _vm.overlayResult.imagePreview || _vm.overlaySrc,
                width: "512",
                height: "256",
                alt: ""
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.overlayVisible
        ? _c(
            "div",
            { staticStyle: { width: "512px", height: "256px" } },
            [
              _c(
                "PinturaEditorOverlay",
                _vm._b(
                  {
                    attrs: {
                      src: _vm.overlaySrc,
                      imageState: _vm.overlayResult.imageState
                    },
                    on: {
                      "pintura:load": function($event) {
                        return _vm.handleOverlayLoad($event)
                      },
                      "pintura:process": function($event) {
                        return _vm.handleOverlayProcess($event)
                      }
                    }
                  },
                  "PinturaEditorOverlay",
                  _vm.editorProps,
                  false
                )
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }