







































































































import {mapMutations, mapState, mapActions} from "vuex";
import i18n from '../i18n'
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],

    name: 'fd-Owner-Existing',

    components: {
        "fd-back-button": () => import('@fd/lib/vue/components/BackButton.vue')
    },

    data: function () { return {
        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are conducting any screen
        // wide actions.
        processing: false,

        // The following will control whether or not the save button shows the processing/loading indicator
        saving: false,

        //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
        breadcrumbs: [
            {
                text: 'Owners',
                disabled: false,
                to: '/owners',
            },
            {
                text: this.$t("loading-dot-dot-dot"),
                disabled: true,
            },
        ],

        owner : {
            name: "",
            alias: "",
            description: "",
            enabled: false
        },
        slidein:false,

        rules: {
            required: value => !!value || i18n.t('common.rule-required'),
            aliascounter: value => !value || value.length <= 20 || i18n.t('common.rule-alias-counter', '20'),
        },

    };},

    methods: {
        // Method used in conjunction with the Save button.
        async save() {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            this.saving = true;
            try {
                await this.updateOwner({
                    id: this.$route.params.id,
                    name: this.owner.name,
                    alias: this.owner.alias,
                    description: this.owner.description,
                    enabled: this.owner.enabled
                });
                this.$router.push('/owners');
            } catch (error) {
                this.inlineMessage.message = this.$t("owners.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.saving = false;
            }
        },
        // the following works with the delete "Action" button in the Datatable.
        async deleteItem() {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.deleteOwner({ id: this.$route.params.id, name: this.owner.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.$router.push('/owners');
            }
        },

        // Method used in conjunction with the Cancel button.
        cancel() {
            // TODO: Should this roll back state rather than rely on requerying?
            this.$router.push('/owners');
        },
        ...mapMutations({
            setOwner: 'SET_OWNER',
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
            setFilteringContext: 'SET_FILTERING_CONTEXT'
        }),
        ...mapActions({
            loadOwner: 'LOAD_OWNER',
            updateOwner: 'UPDATE_OWNER',
            deleteOwner: 'DELETE_OWNER'
        })
    },

    created: async function () {
        // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
        setInterval(() => {
            this.slidein = true;
        }, 100);

        this.processing = true;
        try {
            await this.loadOwner(this.$route.params.id);
            var owner = this.$store.state.owners.fullList.find(x => x.id == this.$route.params.id);
            this.owner.name = owner.name;
            this.owner.alias = owner.alias;
            this.owner.description = owner.description;
            this.owner.enabled = owner.enabled;
            this.notifyNewBreadcrumb({
                text: owner.name,
                to: `/owners/${this.$route.params.id}`
            });
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }

    }
})
