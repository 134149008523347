var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline-item",
    {
      staticClass: "fd-previous-note",
      attrs: {
        "data-id": _vm.note.id,
        large: _vm.note.isPinned && _vm.$vuetify.breakpoint.smAndUp
      },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [
              _vm.note.isPinned
                ? _c(
                    "v-avatar",
                    {
                      staticClass: "fd-pinned-note",
                      attrs: {
                        size: _vm.$vuetify.breakpoint.smAndUp ? "48" : "34"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "white--text",
                          attrs: { small: _vm.$vuetify.breakpoint.xsOnly }
                        },
                        [_vm._v("far fa-thumbtack")]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-avatar",
                    {
                      ref: "normalAvatar",
                      staticClass: "fd-normal-note",
                      attrs: { size: "34" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { small: "" } },
                        [_vm._v("far fa-sticky-note")]
                      )
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { ref: "wrapper", staticClass: "card-wrapper elevation-2" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0 fd-previous-note-name-role" },
                        [
                          _vm._v(
                            "\n            " + _vm._s(_vm.note.name) + " "
                          ),
                          !!_vm.note.role
                            ? _c("span", [
                                _vm._v(" | " + _vm._s(_vm.note.role))
                              ])
                            : _vm._e(),
                          _vm.note.isPinned
                            ? _c("span", [
                                _vm._v(
                                  " (" + _vm._s(_vm.$t("notes.pinned")) + ")"
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      !!_vm.note.date
                        ? _c(
                            "v-row",
                            {
                              staticClass: "pa-0 ma-0 fd-previous-note-datetime"
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.note.date) + " "
                              ),
                              !!_vm.note.time
                                ? _c("span", [
                                    _vm._v(" - " + _vm._s(_vm.note.time))
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "fd-previous-note-text" }, [
                _vm._v(_vm._s(_vm.note.text))
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }