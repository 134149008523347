var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "peoplelist" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("users.no-permission-to-add-or-edit")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: {
                          disabled: !_vm.currentUserCanConfigureSettings,
                          action: _vm.openNewDialog
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-md-0 order-1",
                      attrs: { cols: "12", sm: "12", md: "7", lg: "7" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-0 mt-md-5 mr-5",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            ref: "search",
                            staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-md-1 order-0",
                      attrs: { cols: "12", sm: "12", md: "5", lg: "5" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-md-3 pt-sm-3 pt-3",
                        attrs: {
                          items: _vm.contractorsInUse,
                          label: _vm.$t("users.filter-by-contractor-label"),
                          "item-text": "alias",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.contractorsSelectedForFiltering,
                          callback: function($$v) {
                            _vm.contractorsSelectedForFiltering = $$v
                          },
                          expression: "contractorsSelectedForFiltering"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("fd-people-list", {
            attrs: {
              items: _vm.users,
              tablesearch: _vm.tablesearch,
              processing: _vm.processing,
              anyInactiveUsers: _vm.anyInactiveUsers,
              archivedLoading: _vm.archivedLoading,
              showArchived: _vm.showArchived,
              showArchivedDateRange: _vm.showArchivedDateRange,
              showArchivedMinDate: _vm.showArchivedMinDate,
              showArchivedMaxDate: _vm.showArchivedMaxDate,
              contractorsInUse: _vm.contractorsInUse
            },
            on: {
              navigate: function(item) {
                return _vm.$router.push("/people/" + item.id)
              },
              showArchivedChanged: function(v) {
                return (_vm.showArchived = v)
              },
              showArchivedDateRangeChanged: function(v) {
                return (_vm.showArchivedDateRange = v)
              },
              flipArchived: _vm.flipArchived,
              flipCanLogIn: _vm.flipCanLogIn,
              deleteTableItem: _vm.deleteTableItem
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }