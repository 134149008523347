var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.dialogVisible,
        callback: function($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "data-cy": "newtag.dialog" },
          on: { submit: _vm.onSubmit }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ],
                  attrs: { "data-cy": "newtag.dialog.title" }
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("tags.new-dialog-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "align-center justify-space-between pb-1",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              cy: "newtag.dialog.namefield",
                              label: _vm.$t("common.name"),
                              rules: [_vm.rules.required],
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.tag.name,
                              callback: function($$v) {
                                _vm.$set(_vm.tag, "name", $$v)
                              },
                              expression: "tag.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-1", attrs: { cols: "12" } },
                        [
                          _c("fd-rich-textarea", {
                            attrs: {
                              cy: "newtag.dialog.descriptionfield",
                              disabled: _vm.processing,
                              label: _vm.$t("common.description"),
                              allowImages: false
                            },
                            model: {
                              value: _vm.tag.description,
                              callback: function($$v) {
                                _vm.$set(_vm.tag, "description", $$v)
                              },
                              expression: "tag.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "newtag.dialog.cancelbutton",
                        text: "",
                        disabled: _vm.processing
                      },
                      on: { click: _vm.cancelDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "newtag.dialog.savebutton",
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save-and-close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }