export function textPolyfill(this: Blob): Promise<string> {
  return new Promise<string>(function (resolve, reject) {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result as string);
    });
    reader.addEventListener("error", function (e) {
      reject(reader.error);
    });
    reader.readAsText(this);
  });
}