var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    _vm._b(
      {
        class: [
          _vm.loading ? "view-card-top-color-processing" : "view-card-top-color"
        ],
        attrs: { "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy },
        scopedSlots: _vm._u(
          [
            {
              key: "extension",
              fn: function() {
                return [_vm._t("extension")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "v-toolbar",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }