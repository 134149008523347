var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-container",
        { staticStyle: { padding: "0px" } },
        [
          _c(
            "v-row",
            { staticClass: "mx-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("common.activityid"),
                      disabled: _vm.processing,
                      rules: _vm.workPackageRules.activityID
                    },
                    model: {
                      value: _vm.workPackage.activityID,
                      callback: function($$v) {
                        _vm.$set(_vm.workPackage, "activityID", $$v)
                      },
                      expression: "workPackage.activityID"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("common.name"),
                      disabled: _vm.processing,
                      rules: _vm.workPackageRules.name
                    },
                    model: {
                      value: _vm.workPackage.name,
                      callback: function($$v) {
                        _vm.$set(_vm.workPackage, "name", $$v)
                      },
                      expression: "workPackage.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-date-picker", {
                    attrs: {
                      value: _vm.workPackage.startDate,
                      disabled: _vm.processing,
                      label: _vm.$t("common.startdate"),
                      rules: _vm.workPackageRules.startDate
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.workPackage, "startDate", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("fd-date-picker", {
                    attrs: {
                      value: _vm.workPackage.finishDate,
                      disabled: _vm.processing,
                      label: _vm.$t("common.finishdate"),
                      rules: _vm.workPackageRules.finishDate
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.workPackage, "finishDate", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: _vm.$t("common.status"),
                      disabled: _vm.processing,
                      items: _vm.statusItems,
                      rules: _vm.workPackageRules.status
                    },
                    model: {
                      value: _vm.workPackage.workStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.workPackage, "workStatus", $$v)
                      },
                      expression: "workPackage.workStatus"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-alert",
                        { attrs: { type: _vm.inlineMessage.type } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineMessage.message) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "pl-5 pr-5 pb-5" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      return _vm.$props.closeAction()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.processing,
                    disabled: _vm.processing
                  },
                  on: { click: _vm.saveDialog }
                },
                [_vm._v(_vm._s(_vm.$t("common.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }