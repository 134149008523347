var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        { "fd-quill-container-disabled": _vm.disabled },
        "fd-quill-container"
      ]
    },
    [
      _c("v-subheader", { staticClass: "fd-rich-textarea-sub-header" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm._v(" "),
      _c("div", { ref: "editor", attrs: { "data-cy": _vm.cy } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }