var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fd-file-selection-fab-button",
      attrs: { disabled: _vm.disabled }
    },
    [
      _c(
        "v-file-input",
        _vm._b(
          {
            attrs: {
              "data-cy": _vm.cy,
              disabled: _vm.disabled,
              "prepend-icon": "",
              rounded: "",
              filled: "",
              placeholder: "",
              "persistent-placeholder": false,
              hint: "",
              "persistent-hint": false
            },
            on: { change: _vm.changed },
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          },
          "v-file-input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c("v-icon", { attrs: { disabled: _vm.disabled } }, [_vm._v("mdi-plus")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }