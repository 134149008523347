import { render, staticRenderFns } from "./ActiveWorkForScaffoldDialog.vue?vue&type=template&id=4d1b67ae&"
import script from "./ActiveWorkForScaffoldDialog.vue.ts?vue&type=script&lang=ts&"
export * from "./ActiveWorkForScaffoldDialog.vue.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d1b67ae')) {
      api.createRecord('4d1b67ae', component.options)
    } else {
      api.reload('4d1b67ae', component.options)
    }
    module.hot.accept("./ActiveWorkForScaffoldDialog.vue?vue&type=template&id=4d1b67ae&", function () {
      api.rerender('4d1b67ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/estimationtool/client/views/components/ActiveWorkForScaffoldDialog.vue"
export default component.exports