var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.showSelectionTypeBar
        ? _c(
            "v-row",
            {
              staticClass:
                "justify-end pl-5 pr-5 mr-0 fd-inline-radio-button-qualifier-background",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: "",
                        disabled: _vm.processing || !_vm.countSheetIsEditable,
                        dense: _vm.$vuetify.breakpoint.xsOnly
                      },
                      model: {
                        value: _vm.selectionType,
                        callback: function($$v) {
                          _vm.selectionType = $$v
                        },
                        expression: "selectionType"
                      }
                    },
                    [
                      _vm.canPickIndividual
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$vuetify.breakpoint.xsOnly
                                ? _vm.$t("countsheet.type.individual-short")
                                : _vm.$t("countsheet.type.individual"),
                              disabled: !_vm.canPickIndividual,
                              value: "individual"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hideAssignedTotal && _vm.canRemoveAll
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$vuetify.breakpoint.xsOnly
                                ? _vm.$t("countsheet.type.remove-all-short")
                                : _vm.$t("countsheet.type.remove-all"),
                              disabled: !_vm.canRemoveAll,
                              value: "removeall"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canHaveNoMaterial
                        ? _c("v-radio", {
                            attrs: {
                              label: _vm.$vuetify.breakpoint.xsOnly
                                ? _vm.$t("countsheet.type.not-applicable-short")
                                : _vm.$t("countsheet.type.not-applicable"),
                              disabled: !_vm.canHaveNoMaterial,
                              value: "notapplicable"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          style: _vm.restrictContentHeight
            ? "max-height: calc(100vh - 225px); overflow: auto;"
            : ""
        },
        [
          _vm.countSheetIsDeclined
            ? _c(
                "v-row",
                { staticClass: "pb-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.countSheetIsDeclined
                        ? _c("fd-alert", {
                            staticClass: "mx-3",
                            attrs: {
                              type: "warning",
                              label: _vm.$t(
                                "countsheet.approval.decline-reason"
                              ),
                              comments: _vm.countSheet.reviewDeclineReason,
                              "persistent-comments": ""
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectionType == "removeall"
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "justify-content": "center" },
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c("fd-alert", {
                        attrs: {
                          label: _vm.$t(
                            "countsheet.dialog.remove-all-material-message"
                          ),
                          type: "hint"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectionType == "notapplicable"
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "justify-content": "center" },
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c("fd-alert", {
                        attrs: {
                          label: _vm.$t(
                            "countsheet.dialog.material-not-required-message"
                          ),
                          type: "hint"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectionType == "individual" || _vm.selectionType == "removeall"
            ? _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticStyle: { "border-radius": "0" },
                      attrs: { multiple: "", accordion: "" },
                      model: {
                        value: _vm.openPanels,
                        callback: function($$v) {
                          _vm.openPanels = $$v
                        },
                        expression: "openPanels"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        { staticClass: "fd-count-sheet-group-panel-summary" },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticStyle: { "padding-left": "24px !important" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "countsheet.dialog.summary-panel-title"
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "fd-part-row fd-count-sheet-group-panel-summary-totals",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "fd-group-part-content" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: {
                                            cols: _vm.partIdentifierColumns
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "countsheet.dialog.summary-panel-total-row-label"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0 py-1",
                                          attrs: { cols: _vm.partAmountColumns }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              !_vm.hideAssignedTotal
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-3 order-sm-1",
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          dense: "",
                                                          value:
                                                            _vm.totalAssignedForSummary,
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "countsheet.dialog.assigned"
                                                          ),
                                                          readonly: true
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pl-1 order-1 order-sm-2",
                                                  attrs: {
                                                    cols: "6",
                                                    sm: _vm.hideAssignedTotal
                                                      ? 6
                                                      : 3
                                                  }
                                                },
                                                [
                                                  !_vm.totalHasAnyOverrides
                                                    ? _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          value:
                                                            _vm.totalAddCountForSummary,
                                                          dense: "",
                                                          "hide-details": "",
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "countsheet.dialog.add"
                                                          )
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.totalHasAnyOverrides
                                                    ? _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-overridden-text-field",
                                                        attrs: {
                                                          value:
                                                            _vm.totalAddCountWithOverridesForSummary,
                                                          dense: "",
                                                          hint: _vm.$t(
                                                            "countsheet.dialog.original-value",
                                                            [
                                                              _vm.totalAddCountForSummary
                                                            ]
                                                          ),
                                                          "persistent-hint": "",
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "countsheet.dialog.add-override"
                                                          )
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pl-1 order-2 order-sm-3",
                                                  attrs: {
                                                    cols: "6",
                                                    sm: _vm.hideAssignedTotal
                                                      ? 6
                                                      : 3
                                                  }
                                                },
                                                [
                                                  !_vm.totalHasAnyOverrides
                                                    ? _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-text-field",
                                                        attrs: {
                                                          value:
                                                            _vm.totalRemoveCountForSummary,
                                                          dense: "",
                                                          "hide-details": "",
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "countsheet.dialog.remove"
                                                          )
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.totalHasAnyOverrides
                                                    ? _c("fd-text-field", {
                                                        staticClass:
                                                          "fd-count-sheet-overridden-text-field",
                                                        attrs: {
                                                          value:
                                                            _vm.totalRemoveCountWithOverridesForSummary,
                                                          dense: "",
                                                          hint: _vm.$t(
                                                            "countsheet.dialog.original-value",
                                                            [
                                                              _vm.totalRemoveCountForSummary
                                                            ]
                                                          ),
                                                          "persistent-hint": "",
                                                          readonly: true,
                                                          label: _vm.$t(
                                                            "countsheet.dialog.remove-override"
                                                          )
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              !_vm.hideAssignedTotal
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-4 order-sm-4",
                                                      attrs: {
                                                        cols: "6",
                                                        sm: "3"
                                                      }
                                                    },
                                                    [
                                                      !_vm.totalHasAnyOverrides
                                                        ? _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              value:
                                                                _vm.totalTotalForSummary,
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.total"
                                                              ),
                                                              readonly: true
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.totalHasAnyOverrides
                                                        ? _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-overridden-text-field",
                                                            attrs: {
                                                              value:
                                                                _vm.totalTotalWithOverridesForSummary,
                                                              dense: "",
                                                              hint: _vm.$t(
                                                                "countsheet.dialog.original-value",
                                                                [
                                                                  _vm.totalTotalForSummary
                                                                ]
                                                              ),
                                                              "persistent-hint":
                                                                "",
                                                              readonly: true,
                                                              label: _vm.$t(
                                                                "countsheet.dialog.total-override"
                                                              )
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.countSheetIsEditable
                                        ? _c("v-col", {
                                            staticClass: "px-0",
                                            attrs: {
                                              cols: _vm.partEditClearColumns
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.allowAdminActions
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: {
                                                cols:
                                                  _vm.partAdminActionsColumns
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _vm.countSheetIsOverridable
                                                    ? _c("v-col", {
                                                        attrs: { cols: "12" }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.countSheetIsApprovable
                                                    ? _c("v-col", {
                                                        attrs: { cols: "12" }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.partsForSummary, function(part) {
                                return _c(
                                  "v-row",
                                  {
                                    key: part.id,
                                    staticClass: "fd-part-row",
                                    class: {
                                      "fd-rejected-part": part.rejected,
                                      "fd-overridden-part": part.overridden
                                    },
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "fd-group-part-content" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-1",
                                            attrs: {
                                              cols: _vm.partIdentifierColumns
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                part.rejected || part.overridden
                                                  ? _c(
                                                      "v-col",
                                                      { attrs: { cols: "1" } },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fd-count-sheet-part-status-badge"
                                                          },
                                                          [
                                                            _c(
                                                              "fd-progress-badge",
                                                              {
                                                                attrs: {
                                                                  error:
                                                                    part.rejected,
                                                                  edited:
                                                                    part.overridden
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols:
                                                        part.rejected ||
                                                        part.overridden
                                                          ? 11
                                                          : 12
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fd-count-sheet-part-code-label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              part.publicID
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fd-count-sheet-part-description-label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              part.description
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    !!part.groupName
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fd-count-sheet-part-group-label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  part.groupName.toUpperCase()
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pl-0 py-1",
                                            class: { "pb-0": part.overridden },
                                            attrs: {
                                              cols: _vm.partAmountColumns
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                !_vm.hideAssignedTotal
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pl-1 order-3 order-sm-1",
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            dense: "",
                                                            value:
                                                              part.assigned,
                                                            "hide-details": "",
                                                            label: _vm.$t(
                                                              "countsheet.dialog.assigned"
                                                            ),
                                                            readonly: true
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-1 order-sm-2",
                                                    attrs: {
                                                      cols: "6",
                                                      sm: _vm.hideAssignedTotal
                                                        ? 6
                                                        : 3
                                                    }
                                                  },
                                                  [
                                                    !part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summaryadd",
                                                            dense: "",
                                                            "hide-details": "",
                                                            disabled:
                                                              _vm.processing ||
                                                              part.removeCount >
                                                                0 ||
                                                              !_vm.countSheetCanAddParts,
                                                            readonly: !_vm.countSheetIsEditable,
                                                            label: _vm.$t(
                                                              "countsheet.dialog.add"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partAddValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              part.addCount,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "addCount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.addCount"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    part.overridden
                                                      ? _c("fd-text-field", {
                                                          ref: _vm.fieldRefForPart(
                                                            "add",
                                                            part
                                                          ),
                                                          refInFor: true,
                                                          staticClass:
                                                            "fd-count-sheet-overridden-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summaryaddverride",
                                                            dense: "",
                                                            hint: _vm.$t(
                                                              "countsheet.dialog.original-value",
                                                              [part.addCount]
                                                            ),
                                                            "persistent-hint":
                                                              "",
                                                            disabled:
                                                              _vm.processing ||
                                                              part.removeCountOverride >
                                                                0 ||
                                                              !_vm.countSheetCanAddParts,
                                                            readonly: !_vm.countSheetIsOverridable,
                                                            label: _vm.$t(
                                                              "countsheet.dialog.add-override"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partAddOverrideValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              part.addCountOverride,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "addCountOverride",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.addCountOverride"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pl-1 order-2 order-sm-3",
                                                    attrs: {
                                                      cols: "6",
                                                      sm: _vm.hideAssignedTotal
                                                        ? 6
                                                        : 3
                                                    }
                                                  },
                                                  [
                                                    !part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summaryremove",
                                                            dense: "",
                                                            "hide-details": "",
                                                            disabled:
                                                              _vm.processing ||
                                                              part.addCount >
                                                                0 ||
                                                              (part.assigned <=
                                                                0 &&
                                                                !_vm.hideAssignedTotal) ||
                                                              !_vm.countSheetCanRemoveParts,
                                                            readonly: !_vm.countSheetIsEditable,
                                                            label: _vm.$t(
                                                              "countsheet.dialog.remove"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partRemoveValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              part.removeCount,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "removeCount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.removeCount"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    part.overridden
                                                      ? _c("fd-text-field", {
                                                          staticClass:
                                                            "fd-count-sheet-overridden-text-field",
                                                          attrs: {
                                                            "data-cy":
                                                              "summaryremoveoverride",
                                                            dense: "",
                                                            hint: _vm.$t(
                                                              "countsheet.dialog.original-value",
                                                              [part.removeCount]
                                                            ),
                                                            "persistent-hint":
                                                              "",
                                                            disabled:
                                                              _vm.processing ||
                                                              part.addCountOverride >
                                                                0 ||
                                                              !_vm.countSheetCanRemoveParts,
                                                            readonly: !_vm.countSheetIsOverridable,
                                                            label: _vm.$t(
                                                              "countsheet.dialog.remove-override"
                                                            ),
                                                            numeric: "",
                                                            "highlight-on-focus":
                                                              ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.partRemoveOverrideValueChanged(
                                                                part
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              part.removeCountOverride,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                part,
                                                                "removeCountOverride",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "part.removeCountOverride"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                !_vm.hideAssignedTotal
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "pl-1 order-4 order-sm-4",
                                                        attrs: {
                                                          cols: "6",
                                                          sm: "3"
                                                        }
                                                      },
                                                      [
                                                        !part.overridden
                                                          ? _c(
                                                              "fd-text-field",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-text-field",
                                                                attrs: {
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  label: _vm.$t(
                                                                    "countsheet.dialog.total"
                                                                  ),
                                                                  readonly: true
                                                                },
                                                                model: {
                                                                  value:
                                                                    part.total,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      part,
                                                                      "total",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "part.total"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        part.overridden
                                                          ? _c(
                                                              "fd-text-field",
                                                              {
                                                                staticClass:
                                                                  "fd-count-sheet-text-field",
                                                                attrs: {
                                                                  dense: "",
                                                                  hint: _vm.$t(
                                                                    "countsheet.dialog.original-value",
                                                                    [part.total]
                                                                  ),
                                                                  "persistent-hint":
                                                                    "",
                                                                  label: _vm.$t(
                                                                    "countsheet.dialog.total-override"
                                                                  ),
                                                                  readonly: true
                                                                },
                                                                model: {
                                                                  value:
                                                                    part.totalOverride,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      part,
                                                                      "totalOverride",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "part.totalOverride"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.countSheetIsEditable
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "px-0",
                                                attrs: {
                                                  cols: _vm.partEditClearColumns
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    class: {
                                                      "px-0":
                                                        _vm.$vuetify.breakpoint
                                                          .xsOnly
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.processing ||
                                                        !_vm.countSheetIsEditable,
                                                      text: "",
                                                      "x-small": ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clearPartCounts(
                                                          part
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("common.clear")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.allowAdminActions
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: {
                                                  cols:
                                                    _vm.partAdminActionsColumns
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" }
                                                  },
                                                  [
                                                    _vm.countSheetIsOverridable
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.processing ||
                                                                    _vm.isDeclining ||
                                                                    !_vm.countSheetIsOverridable ||
                                                                    part.overridden,
                                                                  text: "",
                                                                  "x-small": ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.overridePartCounts(
                                                                      part
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "countsheet.dialog.override"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            part.overridden
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.processing ||
                                                                        _vm.isDeclining ||
                                                                        !_vm.countSheetIsOverridable ||
                                                                        !part.overridden,
                                                                      text: "",
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancelOverridePartCounts(
                                                                          part
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "countsheet.dialog.cancel-override"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.countSheetIsApprovable
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.processing ||
                                                                    _vm.isOverriding ||
                                                                    !_vm.countSheetIsApprovable ||
                                                                    part.rejected,
                                                                  color:
                                                                    "error",
                                                                  text: "",
                                                                  "x-small": ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.declinePartCounts(
                                                                      part
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "countsheet.dialog.decline"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            part.rejected
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.processing ||
                                                                        _vm.isOverriding ||
                                                                        !_vm.countSheetIsApprovable ||
                                                                        !part.rejected,
                                                                      color:
                                                                        "error",
                                                                      text: "",
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.cancelDeclinePartCounts(
                                                                          part
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "countsheet.dialog.cancel-decline"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.visibleCountSheetGroups, function(
                        group,
                        index
                      ) {
                        return _c(
                          "v-expansion-panel",
                          {
                            key: index,
                            staticClass: "fd-count-sheet-group-panel"
                          },
                          [
                            _c("v-expansion-panel-header", [
                              _vm._v(" " + _vm._s(group.name))
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-expansion-panel-content",
                              [
                                !group.parts || group.parts.length == 0
                                  ? _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  "justify-content": "center"
                                                },
                                                attrs: { "no-gutters": "" }
                                              },
                                              [
                                                _c("fd-alert", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "countsheet.dialog.group-has-no-parts-message"
                                                    ),
                                                    type: "hint"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(group.sortedParts, function(part) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: part.id,
                                      staticClass: "fd-part-row",
                                      class: {
                                        "fd-rejected-part": part.rejected,
                                        "fd-overridden-part": part.overridden
                                      },
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fd-group-part-content"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: {
                                                cols: _vm.partIdentifierColumns
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  part.rejected ||
                                                  part.overridden
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fd-count-sheet-part-status-badge"
                                                            },
                                                            [
                                                              _c(
                                                                "fd-progress-badge",
                                                                {
                                                                  attrs: {
                                                                    error:
                                                                      part.rejected,
                                                                    edited:
                                                                      part.overridden
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols:
                                                          part.rejected ||
                                                          part.overridden
                                                            ? 11
                                                            : 12
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fd-count-sheet-part-code-label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                part.publicID
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fd-count-sheet-part-description-label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                part.description
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pl-0 py-1",
                                              class: {
                                                "pb-0": part.overridden
                                              },
                                              attrs: {
                                                cols: _vm.partAmountColumns
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  !_vm.hideAssignedTotal
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-3 order-sm-1",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          _c("fd-text-field", {
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              label: _vm.$t(
                                                                "countsheet.dialog.assigned"
                                                              ),
                                                              readonly: true
                                                            },
                                                            model: {
                                                              value:
                                                                part.assigned,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "assigned",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.assigned"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-1 order-sm-2",
                                                      attrs: {
                                                        cols: "6",
                                                        sm: _vm.hideAssignedTotal
                                                          ? 6
                                                          : 3
                                                      }
                                                    },
                                                    [
                                                      !part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "add",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              "data-cy": "add",
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                part.removeCount >
                                                                  0 ||
                                                                !_vm.countSheetCanAddParts,
                                                              readonly: !_vm.countSheetIsEditable,
                                                              label: _vm.$t(
                                                                "countsheet.dialog.add"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partAddValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                part.addCount,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "addCount",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.addCount"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "add",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-overridden-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "addoverride",
                                                              dense: "",
                                                              hint: _vm.$t(
                                                                "countsheet.dialog.original-value",
                                                                [part.addCount]
                                                              ),
                                                              "persistent-hint":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                part.removeCountOverride >
                                                                  0 ||
                                                                !_vm.countSheetCanAddParts,
                                                              readonly: !_vm.countSheetIsOverridable,
                                                              label: _vm.$t(
                                                                "countsheet.dialog.add-override"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "add",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partAddOverrideValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                part.addCountOverride,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "addCountOverride",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.addCountOverride"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-1 order-2 order-sm-3",
                                                      attrs: {
                                                        cols: "6",
                                                        sm: _vm.hideAssignedTotal
                                                          ? 6
                                                          : 3
                                                      }
                                                    },
                                                    [
                                                      !part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "remove",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "remove",
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                part.addCount >
                                                                  0 ||
                                                                (part.assigned <=
                                                                  0 &&
                                                                  !_vm.hideAssignedTotal) ||
                                                                !_vm.countSheetCanRemoveParts,
                                                              readonly: !_vm.countSheetIsEditable,
                                                              label: _vm.$t(
                                                                "countsheet.dialog.remove"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partRemoveValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                part.removeCount,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "removeCount",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.removeCount"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      part.overridden
                                                        ? _c("fd-text-field", {
                                                            ref: _vm.fieldRefForPart(
                                                              "remove",
                                                              part
                                                            ),
                                                            refInFor: true,
                                                            staticClass:
                                                              "fd-count-sheet-overridden-text-field",
                                                            attrs: {
                                                              "data-cy":
                                                                "removeoverride",
                                                              dense: "",
                                                              hint: _vm.$t(
                                                                "countsheet.dialog.original-value",
                                                                [
                                                                  part.removeCount
                                                                ]
                                                              ),
                                                              "persistent-hint":
                                                                "",
                                                              disabled:
                                                                _vm.processing ||
                                                                part.addCountOverride >
                                                                  0 ||
                                                                !_vm.countSheetCanRemoveParts,
                                                              readonly: !_vm.countSheetIsOverridable,
                                                              label: _vm.$t(
                                                                "countsheet.dialog.remove-override"
                                                              ),
                                                              numeric: "",
                                                              "highlight-on-focus":
                                                                ""
                                                            },
                                                            on: {
                                                              keydown: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "up",
                                                                      38,
                                                                      $event.key,
                                                                      [
                                                                        "Up",
                                                                        "ArrowUp"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectPreviousField(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "down",
                                                                      40,
                                                                      $event.key,
                                                                      [
                                                                        "Down",
                                                                        "ArrowDown"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.selectNextField(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return (function(
                                                                    e
                                                                  ) {
                                                                    return _vm.enterPressed(
                                                                      e,
                                                                      "remove",
                                                                      part
                                                                    )
                                                                  })($event)
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.partRemoveOverrideValueChanged(
                                                                  part
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                part.removeCountOverride,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  part,
                                                                  "removeCountOverride",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "part.removeCountOverride"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.hideAssignedTotal
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pl-1 order-4 order-sm-4",
                                                          attrs: {
                                                            cols: "6",
                                                            sm: "3"
                                                          }
                                                        },
                                                        [
                                                          !part.overridden
                                                            ? _c(
                                                                "fd-text-field",
                                                                {
                                                                  staticClass:
                                                                    "fd-count-sheet-text-field",
                                                                  attrs: {
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "countsheet.dialog.total"
                                                                    ),
                                                                    readonly: true
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      part.total,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        part,
                                                                        "total",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "part.total"
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          part.overridden
                                                            ? _c(
                                                                "fd-text-field",
                                                                {
                                                                  staticClass:
                                                                    "fd-count-sheet-text-field",
                                                                  attrs: {
                                                                    dense: "",
                                                                    hint: _vm.$t(
                                                                      "countsheet.dialog.original-value",
                                                                      [
                                                                        part.total
                                                                      ]
                                                                    ),
                                                                    "persistent-hint":
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "countsheet.dialog.total-override"
                                                                    ),
                                                                    readonly: true
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      part.totalOverride,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        part,
                                                                        "totalOverride",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "part.totalOverride"
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.countSheetIsEditable
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "px-0",
                                                  attrs: {
                                                    cols:
                                                      _vm.partEditClearColumns
                                                  }
                                                },
                                                [
                                                  _vm.countSheetIsEditable
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            "x-small": "",
                                                            disabled:
                                                              _vm.processing ||
                                                              !_vm.countSheetIsEditable ||
                                                              (part.addCount ==
                                                                0 &&
                                                                part.removeCount ==
                                                                  0)
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.clearPartCounts(
                                                                part
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.clear"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.allowAdminActions
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: {
                                                    cols:
                                                      _vm.partAdminActionsColumns
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      _vm.countSheetIsOverridable
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.processing ||
                                                                      _vm.isDeclining ||
                                                                      !_vm.countSheetIsOverridable ||
                                                                      part.overridden,
                                                                    text: "",
                                                                    "x-small":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.overridePartCounts(
                                                                        part
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "countsheet.dialog.override"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              part.overridden
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.processing ||
                                                                          _vm.isDeclining ||
                                                                          !_vm.countSheetIsOverridable ||
                                                                          !part.overridden,
                                                                        text:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.cancelOverridePartCounts(
                                                                            part
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "countsheet.dialog.cancel-override"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.countSheetIsApprovable
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.processing ||
                                                                      _vm.isOverriding ||
                                                                      !_vm.countSheetIsApprovable ||
                                                                      part.rejected,
                                                                    color:
                                                                      "error",
                                                                    text: "",
                                                                    "x-small":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.declinePartCounts(
                                                                        part
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "countsheet.dialog.decline"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              part.rejected
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.processing ||
                                                                          _vm.isOverriding ||
                                                                          !_vm.countSheetIsApprovable ||
                                                                          !part.rejected,
                                                                        color:
                                                                          "error",
                                                                        text:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.cancelDeclinePartCounts(
                                                                            part
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "countsheet.dialog.cancel-decline"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }