













































































































import {mapMutations, mapState, mapActions} from "vuex";
import i18n from '../i18n'

export default {

    name: 'fd-Mapping-Existing',

    components: {
        "fd-chip-selector": () => import('@fd/lib/vue/components/ChipItemSelector.vue')
    },

    data: function () { return {
        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are conducting any screen
        // wide actions.
        processing: false,

        // The following will control whether or not the save button shows the processing/loading indicator
        saving: false,

        //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
        breadcrumbs: [
            {
                text: 'Mappings',
                disabled: false,
                to: '/mappings',
            },
            {
                text: this.$t("loading-dot-dot-dot"),
                disabled: true,
            },
        ],

        mapping : {
            name: "",
            description: "",
            validfor: "",
            courseurl: "",
            coursedescription: "",
            enabled: false
        },

        selectedTags: [],

        rules: {
            required: value => !!value || i18n.t('common.rule-required'),
            numbersonly: value => !(value && isNaN(value)) || i18n.t('common.rule-numbers-only')
        }
    };},

    computed: {
        availableTags() {
            return this.$store.state.tags.fullList.filter(x => x.enabled || this.selectedTags.map(x => x.id).findIndex(x => x.id) !== -1);
        }
    },

    methods: {
        // Method used in conjunction with the Save button.
        async save() {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            this.saving = true;
            try {
                await this.updateMapping({
                    ...this.mapping,
                    tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
                });
                this.$router.push('/mappings');
            } catch (error) {
                this.inlineMessage.message = this.$t("mappings.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
                this.saving = false;
            }
        },

        // Method used in conjunction with the Cancel button.
        cancel() {
            // TODO: Should this roll back state rather than rely on requerying?
            this.$router.push('/mappings');
        },
        ...mapMutations({
            setMapping: 'SET_MAPPING',
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB'
        }),
        ...mapActions({
            loadMapping: 'LOAD_MAPPING',
            loadTags: 'LOAD_TAGS',
            updateMapping: 'UPDATE_MAPPING'
        }),
    },

    created: async function () {
        this.processing = true;
        try {
            await Promise.all([
                this.loadMapping(this.$route.params.id),
                this.loadTags()
            ]);
            let mapping = this.$store.state.mappings.fullList.find(x => x.id == this.$route.params.id);
            this.mapping = { ...mapping };
            if (mapping.tagIDs) {
                this.selectedTags = mapping.tagIDs.map(x => this.$store.state.tags.fullList.find(y => y.id == x)).filter(x => x);
            } else {
                this.selectedTags = [];
            }
            this.notifyNewBreadcrumb({
                text: mapping.name,
                to: `/mappings/${this.$route.params.id}`
            });

        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }

    }

}
