import { bufferToBase64, base64ToBuffer } from "./util";
import * as DateUtil from "./datetime";

export function serviceJsonStringifyReplacer(this: any, key: string, value: any): any {
  let realValue = this[key];
  if (realValue instanceof Date) {
    return "`DATE'" + DateUtil.isoString(this[key]);
  } else if (realValue instanceof Uint8Array) {
    return "`BASE64'" + bufferToBase64(value);
  } else if (value === "") {
    // TODO: Do we want to coerce all empty strings to null?
    // Can we use the code generator to only do this in non-string circumstances?
    // Should we handle this on the form binding side instead, which is the real
    // source of the problem?
    return null;
  } else if (typeof value === "string" && value.charAt(0) === "`") {
    return "`" + value;
  } else if (value !== null && typeof value === "object" && Symbol.iterator in value) {
    return Array.from(value);
  }

  return value;
}

export function serviceJsonParseReviver(key: string, value: any): any {
  if (typeof value === "string" && value.charAt(0) === "`") {
    if (value.charAt(1) === "`") return value.substr(1);
    let index = value.indexOf("'", 2);
    let type = value.substring(1, index++);
    value = value.substr(index);
    switch (type) {
      case "DATE":
        return new Date(value);
      case "BASE64":
        return base64ToBuffer(value);
      default:
        return value;
        throw new Error(`Unknown JSON type '${type}' in JSON payload`);
    }
  }

  return value;
}
