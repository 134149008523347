var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.canClose
    ? _c(
        "v-btn",
        _vm._b(
          {
            attrs: {
              "data-cy": "save-button",
              color: "primary",
              disabled: _vm.disabled,
              loading: _vm.loading
            },
            on: {
              click: function(e) {
                return _vm.$emit("click:save", e)
              }
            }
          },
          "v-btn",
          _vm.$attrs,
          false
        ),
        [_vm._v("\n  " + _vm._s(_vm.saveText) + "\n")]
      )
    : _c(
        "v-menu",
        {
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "fd-menu-save-btn",
                          attrs: {
                            "data-cy": "save-menu-open",
                            color: "primary",
                            disabled: _vm.disabled,
                            loading: _vm.loading
                          }
                        },
                        "v-btn",
                        _vm.$attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                      _c("v-icon", {
                        staticStyle: {
                          "padding-left": "6px",
                          "padding-right": "0px"
                        },
                        attrs: { small: "" },
                        domProps: { textContent: _vm._s(_vm.icon) }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { "data-cy": "save-menu-open-options" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { "data-cy": "save-item" },
                  on: {
                    click: function(e) {
                      return _vm.$emit("click:save", e)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.saveText) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { "data-cy": "save-and-close-item" },
                  on: {
                    click: function(e) {
                      return _vm.$emit("click:save-and-close", e)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.saveAndCloseText) + "\n    ")]
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }