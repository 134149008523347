






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapMutations, mapState, mapActions } from "vuex";
import i18n from "../i18n";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";

export default Vue.extend({
  name: "fd-Supplier-Existing",

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue")
  },

  data: function() {
    return {
      // The following tracks the current width of the browser window. It works in conjunction with a EventListener
      // setup in the "created" hook.
      windowWidth: 0,

      // The following is responsible for the inline messages that may be presented to the user.
      inlineMessage: {
        message: null,
        type: "error"
      },

      // This holds the selected tags for the Estimate Overall
      selectedTagsForOverallEstimate: [],

      // This holds the selected tags and suppliers used in the filtering of manually selected parts.
      selectedTagsForPartsSelection: [],
      selectedSuppliersForPartsSelection: [],

      // The following will control whether the controls on screen are disabled while we are conducting any screen
      // wide actions.
      processing: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      // Used to control an animation for the view coming into visibility to the user.
      slidein: false,

      // These objects are used for the view specific "Tags" filtering.
      tagsSelectedForFiltering: [],

      // These objects are used for the view specific "Suppliers" filtering.
      suppliersSelectedForFiltering: [],

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: "Estimates",
          disabled: false,
          to: "/estimates"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ],

      validfrommenu: false,
      validuntilmenu: false,

      simulatederror: false,

      estimate: {
        tagnumber: "",
        clientID: "",
        projectID: "",
        areaID: "",
        subareaID: "",
        estimatedby: "",
        validfrom: null,
        validuntil: null,
        regionID: "",
        productivity: "",
        labourrate: "",
        riskadder: "",
        usecleating: false,
        uselashing: false,
        usecarpentry: false,
        useother: false
      },

      part: {
        publicID: "",
        name: "",
        description: "",
        weight: "",
        supplierID: null,
        mpp: "",
        cleatingMPP: "",
        lashingMPP: "",
        carpentryMPP: "",
        otherMPP: "",
        enabled: true
      },

      // column headers for the table used for manually selecting parts.
      headersparts: [
        {
          text: "Name",
          value: "name",
          sortable: true,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      // The following is mockup data for the potential summation object for the estimate
      estimatesummation: [
        {
          name: "summation-mobilization",
          value: 41.11,
          hightlight: false
        },
        {
          name: "summation-erection",
          value: 332.97,
          hightlight: false
        },
        {
          name: "summation-dismantle",
          value: 221.98,
          hightlight: false
        },
        {
          name: "summation-demobilization",
          value: 41.11,
          hightlight: false
        },
        {
          name: "summation-hoarding",
          value: 0.0,
          hightlight: false
        },
        {
          name: "summation-modify",
          value: 49.95,
          hightlight: false
        },
        {
          name: "summation-total",
          value: 687.12,
          hightlight: true
        },
        {
          name: "summation-weight-kg",
          value: 41522.4,
          hightlight: false
        },
        {
          name: "summation-pieces",
          value: 2966,
          hightlight: false
        },
        {
          name: "summation-erection-mpp",
          value: 6.74,
          hightlight: true
        },
        {
          name: "summation-dismantle-mpp",
          value: 4.49,
          hightlight: true
        }
      ],

      // mock objects used to display parts in the manually selected parts section.
      mockPartsSelection: [
        {
          name: "RL.IFPL.0880.01",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 15
        },
        {
          name: "RL.IFPL.0880.02",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 8
        },
        {
          name: "RL.IFPL.0880.03",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 2
        },
        {
          name: "RL.IFPL.0880.04",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 1
        },
        {
          name: "RL.IFPL.0880.05",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 15
        },
        {
          name: "RL.IFPL.0880.06",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 127
        },
        {
          name: "RL.IFPL.0880.07",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 34
        },
        {
          name: "RL.IFPL.0880.08",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 19
        },
        {
          name: "RL.IFPL.0880.09",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 3
        },
        {
          name: "RL.IFPL.0880.10",
          description: "FILLER BOARD 0.88M/2'10 5/8\" (COVER PLATE)",
          quantity: 227
        },
        {
          name: "RL.IFPL.0880.11",
          description: "FILLER BOARD 0.90M/2'10 5/8\" (COVER PLATE)",
          quantity: 16
        },
        {
          name: "RL.IFPL.0880.12",
          description: "FILLER BOARD 0.95M/2'10 5/8\" (COVER PLATE)",
          quantity: 29
        }
      ],

      // object used in the mockup of how parts that error out in the bcounts submission will appear.
      erroredparts: [
        {
          name: "RL.ICPF.0736.01",
          quantity: 15,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.0990.01",
          quantity: 7,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1286.01",
          quantity: 10,
          ignored: false,
          errormessage: "ID needs to be filled in."
        },
        {
          name: "RL.ICPF.1570.01",
          quantity: 16,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1577.02",
          quantity: 8,
          ignored: false,
          errormessage: null
        }
      ],

      // object used to mockup how ignored parts will appear to the user.
      ignoredparts: [
        {
          name: "RL.ICPF.0736.01",
          quantity: 15,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.0990.01",
          quantity: 7,
          ignored: false,
          errormessage: null
        },
        {
          name: "RL.ICPF.1286.01",
          quantity: 10,
          ignored: false,
          errormessage: "ID needs to be filled in."
        }
      ],

      // The following objects are used in the table shown to users when there are errors
      // based on what they pasted into the bcounts processing.
      expanded: [],
      singleExpand: true,
      showExpand: true,
      tablesearch: "",
      headersForErroredParts: [
        {
          text: "",
          value: "ignored",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Name",
          value: "name",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Actions",
          value: "data-table-expand",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      //The following objects are related to the "ignored" parts list
      expandedIgnoredParts: [],
      tablesearchIgnoredParts: "",
      headersIgnoredParts: [
        {
          text: "Name",
          value: "name",
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Quantity",
          value: "quantity",
          hideWhenSmall: true,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        },
        {
          text: "Actions",
          value: "data-table-expand",
          sortable: false,
          hideWhenSmall: false,
          hideWhenMedium: false,
          hideWhenLarge: false,
          hideWhenLargest: false
        }
      ],

      // variables to hold potential error states.
      allErroredPartsThatRemainHaveBeenIgnored: false,
      partserror: false,
      detailserror: false,

      selectedTags: [],

      rules: {
        required: value => !!value || i18n.t("common.rule-required"),
        numbersonly: value => !(value && isNaN(value)) || i18n.t("common.rule-numbers-only")
      }
    };
  },

  computed: {
    availableTagsForOverallEstimate() {
      return this.$store.getters.getSortedEnabledInUseTags(this.selectedTags);
    },
    formatedValidFromDate() {
      return this.estimate.validfrom ? stripTimeFromLocalizedDateTime(this.estimate.validfrom) : "";
    },
    formatedValidUntilDate() {
      return this.estimate.validuntil
        ? stripTimeFromLocalizedDateTime(this.estimate.validuntil)
        : "";
    },

    i18nLocale() { return this.$store.state.datepickerLanguage; },

    modifiers() {
      return this.$store.state.modifiers.fullList;
    },
    availableSuppliersForPartsSelection() {
      return this.$store.state.suppliers.fullList;
    },
    availableTagsForPartsSelection() {
      return this.$store.getters.sortedEnabledTags;
    },
    computedPartsHeaders() {
      if (this.windowWidth >= 1264) {
        return this.headersparts.filter(h => !h.hideWhenLargest);
      } else if (this.windowWidth < 1264 && this.windowWidth >= 960) {
        return this.headersparts.filter(h => !h.hideWhenLarge);
      } else if (this.windowWidth < 960 && this.windowWidth >= 600) {
        return this.headersparts.filter(h => !h.hideWhenMedium);
      } else if (this.windowWidth < 600) {
        return this.headersparts.filter(h => !h.hideWhenSmall);
      } else {
        return this.headersparts;
      }
    },

    computedHeadersForErroredParts() {
      if (this.windowWidth >= 1264) {
        return this.headersForErroredParts.filter(h => !h.hideWhenLargest);
      } else if (this.windowWidth < 1264 && this.windowWidth >= 960) {
        return this.headersForErroredParts.filter(h => !h.hideWhenLarge);
      } else if (this.windowWidth < 960 && this.windowWidth >= 600) {
        return this.headersForErroredParts.filter(h => !h.hideWhenMedium);
      } else if (this.windowWidth < 600) {
        return this.headersForErroredParts.filter(h => !h.hideWhenSmall);
      } else {
        return this.headersForErroredParts;
      }
    }
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.save();
    },
    // Method used in conjunction with the Save button.
    async save() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!this.$refs.form.validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        await this.updateSupplier({
          ...this.supplier,
          tagIDs: this.selectedTags.length > 0 ? this.selectedTags.map(x => x.id) : null
        });
        this.$router.push("/suppliers");
      } catch (error) {
        this.inlineMessage.message = this.$t("suppliers.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteSupplier({ id: this.$route.params.id, name: this.supplier.name });
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
        this.$router.push("/suppliers");
      }
    },

    mockSavePartToCatalog(item) {
      this.erroredparts = this.erroredparts.filter(x => x != item);
    },

    mockIgnorePart(item: any) {
      this.erroredparts = this.erroredparts.filter(x => x != item);
    },

    // This function is used to "Cancel" the adding of a new item to the parts catalog. Essentially it just
    // closes the "expanded" item screen for the row in question. This screen only allows one row to be open
    // so this function could have just emptied out the entire array, but instead it has been coded so that it
    // could work with a different table that allows multiple expanded rows.
    cancelItemAdd(item: any) {
      this.expanded = this.expanded.filter(x => x != item);
    },
    // This function is used to close any expanded rows within the Ignored Parts table.
    cancelItemAddIgnoreParts(item: any) {
      this.expandedIgnoredParts = this.expandedIgnoredParts.filter(x => x != item);
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimates");
    },
    ...mapMutations({
      setSupplier: "SET_SUPPLIER",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadSupplier: "LOAD_SUPPLIER",
      loadTags: "LOAD_TAGS",
      updateSupplier: "UPDATE_SUPPLIER",
      deleteSupplier: "DELETE_SUPPLIER"
    })
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Listen to the "resize" even for the browser so we always know the width and can use that
    // knowledge for various responsive layout reasons.
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.windowWidth = window.innerWidth;

    try {
      await Promise.all([this.loadSupplier(this.$route.params.id), this.loadTags()]);
      let supplier = this.$store.state.suppliers.fullList.find(x => x.id == this.$route.params.id);
      this.supplier = { ...supplier };
      if (supplier.tagIDs) {
        this.selectedTags = supplier.tagIDs
          .map(x => this.$store.state.tags.fullList.find(y => y.id == x))
          .filter(x => x);
      } else {
        this.selectedTags = [];
      }
      this.notifyNewBreadcrumb({
        text: supplier.name,
        to: `/suppliers/${this.$route.params.id}`
      });
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});
