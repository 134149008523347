var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-switch",
    _vm._b(
      {
        staticClass: "fd-show-archived-switch mr-5",
        staticStyle: { "margin-top": "16px" },
        attrs: {
          "data-cy": _vm.cy,
          "input-value": _vm.value,
          label: _vm.$vuetify.breakpoint.smAndUp
            ? _vm.$t("common.show-archived-label")
            : _vm.$t("common.show-archived-label-short")
        },
        on: {
          change: function(v) {
            return _vm.$emit("input", v)
          }
        }
      },
      "v-switch",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }