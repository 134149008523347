var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        [
          _c("v-progress-circular", {
            staticClass: "mr-3",
            attrs: {
              indeterminate: true,
              rotate: 0,
              size: 32,
              width: 4,
              color: _vm.loadingColor
            }
          })
        ],
        1
      )
    : !!_vm.value && ("" + _vm.value).trim().length > 0
    ? _c("div", { class: _vm.valueClass, style: _vm.valueStyle }, [
        _vm._v("\n  " + _vm._s(_vm.value) + "\n")
      ])
    : !_vm.hideNoValuePlaceholder
    ? _c(
        "div",
        {
          staticClass: "fd-item-placeholder",
          class: _vm.noValueClass,
          style: _vm.noValueStyle
        },
        [
          _vm._v(
            "\n  " +
              _vm._s(
                !!_vm.noValueText ? _vm.noValueText : _vm.$t(_vm.noValueKey)
              ) +
              "\n"
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }