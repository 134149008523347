var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "partslist" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: { action: _vm.openNewDialog }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 order-md-0 order-1",
                      attrs: {
                        cols: "12",
                        md:
                          !!_vm.suppliersInUse && _vm.suppliersInUse.length > 1
                            ? 7
                            : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5 ml-1 ml-sm-0",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            ref: "search",
                            staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !!_vm.suppliersInUse && _vm.suppliersInUse.length > 1
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-md-1 order-0",
                          attrs: { cols: "12", md: "5" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-md-3 pt-sm-3 pt-3",
                            attrs: {
                              items: _vm.suppliersInUse,
                              label: _vm.$t(
                                "projects.parts-selection-supplier-filter-label"
                              ),
                              "item-text": "alias",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.suppliersSelectedForFiltering,
                              callback: function($$v) {
                                _vm.suppliersSelectedForFiltering = $$v
                              },
                              expression: "suppliersSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:publicID",
                value: _vm.$t("parts.public-id"),
                expression: "$t('parts.public-id')",
                arg: "publicID"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:name.hide-when-extra-small",
                value: _vm.$t("common.name"),
                expression: "$t('common.name')",
                arg: "name",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:description",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "description"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:weight.hide-when-medium",
                value: _vm.$t("parts.weightkg"),
                expression: "$t('parts.weightkg')",
                arg: "weight",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:mpp.hide-when-header-text-empty.hide-when-medium",
                value: !_vm.currentUserCanConfigurePrivateSettings
                  ? ""
                  : _vm.$t("parts.mpp"),
                expression:
                  "\n        !currentUserCanConfigurePrivateSettings ? '' : $t('parts.mpp')\n      ",
                arg: "mpp",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-medium": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:cleatingMPP.hide-when-header-text-empty.hide-when-large",
                value: !_vm.currentUserCanConfigurePrivateSettings
                  ? ""
                  : _vm.$t("parts.cleating"),
                expression:
                  "\n        !currentUserCanConfigurePrivateSettings ? '' : $t('parts.cleating')\n      ",
                arg: "cleatingMPP",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-large": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:lashingMPP.hide-when-header-text-empty.hide-when-large",
                value: !_vm.currentUserCanConfigurePrivateSettings
                  ? ""
                  : _vm.$t("parts.lashing"),
                expression:
                  "\n        !currentUserCanConfigurePrivateSettings ? '' : $t('parts.lashing')\n      ",
                arg: "lashingMPP",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-large": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:carpentryMPP.hide-when-header-text-empty.hide-when-large",
                value: !_vm.currentUserCanConfigurePrivateSettings
                  ? ""
                  : _vm.$t("parts.carpentry"),
                expression:
                  "\n        !currentUserCanConfigurePrivateSettings ? '' : $t('parts.carpentry')\n      ",
                arg: "carpentryMPP",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-large": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:otherMPP.hide-when-header-text-empty.hide-when-large",
                value: !_vm.currentUserCanConfigurePrivateSettings
                  ? ""
                  : _vm.$t("parts.other"),
                expression:
                  "\n        !currentUserCanConfigurePrivateSettings ? '' : $t('parts.other')\n      ",
                arg: "otherMPP",
                modifiers: {
                  "hide-when-header-text-empty": true,
                  "hide-when-large": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:rentalRate.hide-when-medium",
                value: _vm.$t("parts.rental-rate"),
                expression: "$t('parts.rental-rate')",
                arg: "rentalRate",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:costUsed.hide-when-medium",
                value: _vm.$t("parts.cost-used"),
                expression: "$t('parts.cost-used')",
                arg: "costUsed",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:costNew.hide-when-medium",
                value: _vm.$t("parts.cost-new"),
                expression: "$t('parts.cost-new')",
                arg: "costNew",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:designation.hide-when-medium",
                value: _vm.$t("parts.designation"),
                expression: "$t('parts.designation')",
                arg: "designation",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:countSheetGroupName.hide-when-large",
                value: _vm.$t("parts.count-sheet-group-name"),
                expression: "$t('parts.count-sheet-group-name')",
                arg: "countSheetGroupName",
                modifiers: { "hide-when-large": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:action.no-sort.hide-when-extra-small",
                arg: "action",
                modifiers: { "no-sort": true, "hide-when-extra-small": true }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.show-when-extra-small",
                value: function(item) {
                  return _vm.$router.push("/parts/" + item.id)
                },
                expression: "item => $router.push(`/parts/${item.id}`)",
                modifiers: { "show-when-extra-small": true }
              }
            ],
            ref: "datatable",
            class: [
              "fd-parts-list",
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-action-table",
              "fd-enabling-table"
            ],
            attrs: {
              page: _vm.tablepage,
              "data-cy": "parts-list",
              items: _vm.parts,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["publicID"],
              "mobile-breakpoint": "0"
            },
            on: {
              "update:page": function($event) {
                _vm.tablepage = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing
                      },
                      on: {
                        "input:showArchived": function(v) {
                          return (_vm.showArchived = v)
                        },
                        "input:showArchivedDateRange": function(v) {
                          return (_vm.showArchivedDateRange = v)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.archived",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.showArchived
                      ? _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                value: item.archived,
                                disabled: _vm.processing
                              },
                              on: {
                                input: function($event) {
                                  return _vm.flipArchived(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.supplierID",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.lookupSupplier(item.supplierID)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.weight",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "weight" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "weight",
                        value: item.weight,
                        rules: _vm.partRules.weight,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "weight", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.weightkg"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("weight", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("weight", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "weight", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.mpp",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "mpp" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "mpp",
                        value: item.mpp,
                        rules: _vm.partRules.mpp,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "mpp", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.mpp"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("mpp", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("mpp", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "mpp", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.lashingMPP",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "lashingMPP" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "lashingMPP",
                        value: item.lashingMPP != null ? item.lashingMPP : "0",
                        rules: _vm.partRules.lashingMPP,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "lashingMPP", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.lashing"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("lashingMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("lashingMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "lashingMPP", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.cleatingMPP",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "cleatingMPP" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "cleatingMPP",
                        value:
                          item.cleatingMPP != null ? item.cleatingMPP : "0",
                        rules: _vm.partRules.cleatingMPP,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "cleatingMPP", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.cleating"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("cleatingMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("cleatingMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "cleatingMPP", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.carpentryMPP",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "carpentryMPP" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "carpentryMPP",
                        value:
                          item.carpentryMPP != null ? item.carpentryMPP : "0",
                        rules: _vm.partRules.carpentryMPP,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "carpentryMPP", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.carpentry"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("carpentryMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("carpentryMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "carpentryMPP", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.otherMPP",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "otherMPP" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "otherMPP",
                        value: item.otherMPP != null ? item.otherMPP : "0",
                        rules: _vm.partRules.otherMPP,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "otherMPP", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.other"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("otherMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("otherMPP", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "otherMPP", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.rentalRate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "rentalRate" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "rentalRate",
                        value: item.rentalRate != null ? item.rentalRate : "0",
                        rules: _vm.partRules.rentalRate,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "rentalRate", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.rental-rate"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("rentalRate", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("rentalRate", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "rentalRate", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.costUsed",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "costUsed" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "costUsed",
                        value: item.costUsed != null ? item.costUsed : "0",
                        rules: _vm.partRules.costUsed,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "costUsed", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.cost-used"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("costUsed", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("costUsed", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "costUsed", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.costNew",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "costNew" + item.id,
                      staticClass: "fd-short-table-input",
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "costNew",
                        value: item.costNew != null ? item.costNew : "0",
                        rules: _vm.partRules.costNew,
                        numeric: "",
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "costNew", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.cost-new"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("costNew", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("costNew", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "costNew", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.designation",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("fd-text-field", {
                      ref: "designation" + item.id,
                      attrs: {
                        "data-id": item.id,
                        "data-cy": "designation",
                        value: item.designation != null ? item.designation : "",
                        rules: _vm.partRules.designation,
                        outlined: false,
                        "auto-trigger-change": ""
                      },
                      on: {
                        change: function(value) {
                          return _vm.saveField(item, "designation", value)
                        },
                        error: function(msg, txt) {
                          return _vm.showInlineTextFieldErrorMessage(
                            _vm.$t("parts.designation"),
                            msg,
                            txt
                          )
                        },
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectPreviousField("designation", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            return _vm.selectNextField("designation", item)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function(e) {
                              return _vm.enterPressed(e, "designation", item)
                            })($event)
                          }
                        ]
                      }
                    })
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-1-action-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: "/parts/" + item.id
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }