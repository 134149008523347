import FDVue from "../";

export default FDVue.extend({
  name: "fd-add-file-button",

  inheritAttrs: false,

  props: {
    disabled: { type: Boolean, default: false },
    cy: { type: String, default: "fd-add-file-button" }
  },

  data: function() {
    return {
      file: null as any
    };
  },

  methods: {
    changed(v: any) {
      console.log(`AddFileButton.changed\n\tv: ${v}\n\tfile: ${this.file}`);
      this.$emit("change", v);

      let obj = this;
      this.$nextTick(() => {
        obj.file = null;
      });
    }
  },

  computed: {},

  watch: {}
});
