





















































































































import {mapMutations, mapState, mapActions} from "vuex";
import { createNewRegion } from "./components/RegionNewDialog.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";

export default FDVue.extend({
    mixins: [errorHandling],

    name: 'fd-Regions',

    directives: {
        fdColumn: FDColumnDirective,
        fdRowNavigate: FDRowNavigateDirective
    },

    data: () => ({
        // The following is responsible for the inline messages that may be presented to the user.
        inlineMessage: {
            message: null,
            type: 'error',
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following is used to define the breadcrumbs navigation for the view.
        breadcrumbs: [
            {
                text: 'Regions',
                disabled: true,
            },
        ],
    }),

    computed: {
        regions () { return this.$store.state.regions.fullList; },

        tablesearch: {
            get () {
                return this.$store.state.filters.find(x => x.context == this.$store.state.filteringContext)!.searchStringForFiltering;
            },
            set (val) {
                this.$store.commit('SET_SEARCH_STRING_FOR_FILTERING', val)
            },
        },
    },

    methods: {
        async openNewDialog() {
            await createNewRegion();
        },

        // the following works with the delete "Action" button in the Datatable.
        async deleteTableItem (item) {
            this.inlineMessage.message = null;
            this.processing = true;
            try {
                await this.deleteRegion({ id: item.id, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }

        },
        async flipEnabled (item) {
            this.inlineMessage.message = null;
            this.processing = true
            try {
                await this.updateRegion({ id: item.id, enabled: !item.enabled, name: item.name });
            } catch (error) {
                this.inlineMessage.message = this.$t("unexpected-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }

        },
        ...mapMutations({
            notifyNewBreadcrumb: 'NOTIFY_NEW_BREADCRUMB',
            setFilteringContext: 'SET_FILTERING_CONTEXT'
        }),
        ...mapActions({
            loadRegions: 'LOAD_REGIONS',
            updateRegion: 'UPDATE_REGION',
            deleteRegion: 'DELETE_REGION'
        })
    },

    created: async function () {
        // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
        // a sub screen of something that is currently filtered by their choices that those choices will be
        // preserved as they move between the two screens.
        this.setFilteringContext({
            context: "regions",
            parentalContext: null,
            searchStringForFiltering: "",
            tagsForFiltering: [],
            suppliersForFiltering: []
        });


        this.notifyNewBreadcrumb({
            text: "Regions",
            to: "/regions",
            resetHistory: true
        });
        this.processing = true;
        try {
            await this.loadRegions();
        } catch (error) {
            this.inlineMessage.message = this.$t("unexpected-network-error");
            this.inlineMessage.type = "error";
        } finally {
            this.processing = false;
        }
    }
})
