












































































import {mapState, mapMutations, mapActions} from "vuex";
import i18n from '../../i18n'
import Vue from "vue";
import dialogSupport, {createDialog} from "@fd/lib/vue/mixins/dialogSupport";

const RegionNewDialog = Vue.extend (  {
    name: 'fd-region-new-dialog',
    mixins: [dialogSupport],

    data: () => ({
        dialogVisible: false,

        region : {
            name: "",
            description: ""
        },

        rules: {
            required: value => !!value || i18n.t('common.rule-required'),
        },

        // The following will control whether the controls on screen are disabled while we are processing.
        processing: false,

        // The following is responsible for the inline messages that may be presented to the user.
        // If the message property is null then no message will be shown to the user.
        // The type can be one of the following strings:
        // 'success'
        // 'info'
        // 'warning'
        // 'error'
        inlineMessage: {
            message: null,
            type: 'error'
        },
    }),

    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.saveDialog();
        },

        // Method used in conjunction with the Cancel dialog.
        cancelDialog() {
            this.closeDialog!(false);
        },

        //Method used in conjunction with new view dialog.
        async saveDialog () {
            // First reset the inline message if there are any.
            this.inlineMessage.message = '';

            if (!this.$refs.form.validate()) {
                return;
            }

            this.processing = true;
            try {
                await this.addRegion({
                    name: this.region.name,
                    description: this.region.description,
                    enabled: true
                });
                this.closeDialog!(true);
            } catch (error) {
                this.inlineMessage.message = this.$t("regions.save-network-error");
                this.inlineMessage.type = "error";
            } finally {
                this.processing = false;
            }
        },
        ...mapActions({
            addRegion: 'ADD_REGION'
        })
    }
});

export default RegionNewDialog;

export async function createNewRegion(): Promise<boolean> {
    let dialog = createDialog(RegionNewDialog);
    return await dialog.showDialog!();
}

