var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "externallinkdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm.isEditing
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "attachments.external-link.edit-dialog-title"
                            )
                          )
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("attachments.external-link.new-dialog-title")
                          )
                        )
                      ])
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "external-link-form",
              on: { submit: _vm.onSubmit }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-2", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-0 mt-7",
                      attrs: { align: "center", cols: "12" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t("attachments.external-link.name-label"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.required]
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "justify-space-between pt-1 pb-0",
                      attrs: { align: "center", cols: "12" }
                    },
                    [
                      _c("fd-text-field", {
                        attrs: {
                          label: _vm.$t("attachments.external-link.url-label"),
                          "persistent-hint": "",
                          hint: _vm.$t("attachments.external-link.url-hint"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.required]
                        },
                        model: {
                          value: _vm.address,
                          callback: function($$v) {
                            _vm.address = $$v
                          },
                          expression: "address"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message
                ? _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "cancel",
                        text: "",
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(null)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "save",
                        color: "primary",
                        loading: _vm.processing,
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.saveDialog }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isEditing
                              ? _vm.$t("common.save")
                              : _vm.$t("common.add")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }