var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fd-environment-branding" },
        [
          _c("v-img", {
            staticClass: "mt-3",
            attrs: {
              src: "/assets/img/sample-dashboard-backdrop.jpg",
              gradient: "to top left, rgba(0,0,0,.05), rgba(0,0,0,.6)"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "fd-environment-branding-title" }, [
            _c("div", [_vm._v(_vm._s(_vm.siteName))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-container",
            { staticClass: "pt-1", staticStyle: { "max-width": "100%" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.showStatistics
                    ? _c(
                        "v-col",
                        {
                          staticClass: "py-lg-3 px-0",
                          attrs: { cols: "12", lg: "12" }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 ma-0", attrs: { dense: "" } },
                            [
                              _vm.currentUserCanViewScaffoldRequestApprovals
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: { col: "4", lg: "4" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            "/scaffoldrequestapprovals"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "pt-0 pb-0 pb-lg-0 px-0 ma-0"
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "fd-landing-page-metrics",
                                              attrs: { dark: "" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [
                                                  _vm.$vuetify.breakpoint.xs
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 ma-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "fd-landing-page-metrics-number"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.requestsToApproveCount
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 mx-0 fd-landing-page-metrics-title",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "8"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp
                                                                      ? _vm.$t(
                                                                          "landing-page.requests-metric-title"
                                                                        )
                                                                      : _vm.$t(
                                                                          "landing-page.requests-metric-title-short"
                                                                        )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.$vuetify.breakpoint.xs
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-landing-page-metrics-number"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.requestsToApproveCount
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-landing-page-metrics-title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp
                                                                      ? _vm.$t(
                                                                          "landing-page.requests-metric-title"
                                                                        )
                                                                      : _vm.$t(
                                                                          "landing-page.requests-metric-title-short"
                                                                        )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  attrs: { col: "4", lg: "4" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push("/todolist")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pt-0 pb-0 pb-lg-0 px-0 ma-0"
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "fd-landing-page-metrics",
                                          attrs: { dark: "" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "text-h5" },
                                            [
                                              _vm.$vuetify.breakpoint.xs
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "pa-0 ma-0"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "4"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-landing-page-metrics-number"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.walkdownsToCompleteCount
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pa-0 mx-0 fd-landing-page-metrics-title",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "8"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp
                                                                  ? _vm.$t(
                                                                      "landing-page.walk-downs-metric-title"
                                                                    )
                                                                  : _vm.$t(
                                                                      "landing-page.walk-downs-metric-title-short"
                                                                    )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.$vuetify.breakpoint.xs
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "pa-0 ma-0"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fd-landing-page-metrics-number"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.walkdownsToCompleteCount
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fd-landing-page-metrics-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$vuetify
                                                                  .breakpoint
                                                                  .lgAndUp
                                                                  ? _vm.$t(
                                                                      "landing-page.walk-downs-metric-title"
                                                                    )
                                                                  : _vm.$t(
                                                                      "landing-page.walk-downs-metric-title-short"
                                                                    )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.currentUserCanViewWorkOrderSchedule && false
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: { col: "4", lg: "4" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push("/scheduler")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "py-0 px-0 ma-0" },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "fd-landing-page-metrics",
                                              attrs: { dark: "" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [
                                                  _vm.$vuetify.breakpoint.xs
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 ma-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "fd-landing-page-metrics-number"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.estimatesToCompleteCount
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pa-0 mx-0 fd-landing-page-metrics-title",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "8"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp
                                                                      ? _vm.$t(
                                                                          "landing-page.estimates-metric-title"
                                                                        )
                                                                      : _vm.$t(
                                                                          "landing-page.estimates-metric-title-short"
                                                                        )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.$vuetify.breakpoint.xs
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "pa-0 ma-0"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-landing-page-metrics-number"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.estimatesToCompleteCount
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "fd-landing-page-metrics-title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp
                                                                      ? _vm.$t(
                                                                          "landing-page.estimates-metric-title"
                                                                        )
                                                                      : _vm.$t(
                                                                          "landing-page.estimates-metric-title-short"
                                                                        )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canSubmitScaffoldRequests
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "fd-landing-page-card fd-landing-page-card-blue",
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-h5" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "9" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "margin-top": "-3px"
                                              },
                                              on: {
                                                click: _vm.addScaffoldRequest
                                              }
                                            },
                                            [_vm._v("fal fa-plus-circle")]
                                          ),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "short-cut-card.scaffold-request"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "pa-0 ma-0 justify-end"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "d-flex d-lg-none",
                                                  staticStyle: {
                                                    "margin-top": "2px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    block: "",
                                                    small:
                                                      _vm.$vuetify.breakpoint.xs
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.addScaffoldRequest
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("common.add")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "short-cut-card.scaffold-request-description"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "d-none d-lg-flex" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        block: "",
                                        small: _vm.$vuetify.breakpoint.xs
                                      },
                                      on: { click: _vm.addScaffoldRequest }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("common.add-now")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.curUserAccess.canImportWorkOrders
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "fd-landing-page-card fd-landing-page-card-green",
                              attrs: { dark: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-h5" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "9" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "margin-top": "-3px"
                                              },
                                              on: {
                                                click: _vm.importWorkOrders
                                              }
                                            },
                                            [_vm._v("fal fa-arrow-circle-up")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "short-cut-card.import-work-orders"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "pa-0 ma-0 justify-end"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "d-flex d-lg-none",
                                                  staticStyle: {
                                                    "margin-top": "2px"
                                                  },
                                                  attrs: {
                                                    outlined: "",
                                                    block: "",
                                                    small:
                                                      _vm.$vuetify.breakpoint.xs
                                                  },
                                                  on: {
                                                    click: _vm.importWorkOrders
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("common.upload")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-card-subtitle", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "short-cut-card.import-work-orders-description"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "d-none d-lg-flex" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        block: "",
                                        small: _vm.$vuetify.breakpoint.xs
                                      },
                                      on: { click: _vm.importWorkOrders }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("common.upload-now")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.lgAndUp
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "fd-landing-page-card-placeholder d-none d-lg-flex",
                              attrs: { dark: "" }
                            },
                            [
                              _c("v-card-title"),
                              _vm._v(" "),
                              _c("v-card-subtitle"),
                              _vm._v(" "),
                              _c("v-card-actions")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.lgAndUp &&
                  !_vm.curUserAccess.canSubmitScaffoldRequests
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c("v-card", {
                            staticClass:
                              "fd-landing-page-card-placeholder d-none d-lg-flex",
                            attrs: { dark: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.lgAndUp &&
                  !_vm.curUserAccess.canImportWorkOrders
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "4" } },
                        [
                          _c("v-card", {
                            staticClass:
                              "fd-landing-page-card-placeholder d-none d-lg-flex",
                            attrs: { dark: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view mt-3 mt-sm-5 notifications" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                {
                  staticStyle: { "font-size": "44px", "padding-right": "10px" }
                },
                [_vm._v("mdi-bell")]
              ),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("notifications.title")) + "\n      "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("common.search"),
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.tablesearch,
                  callback: function($$v) {
                    _vm.tablesearch = $$v
                  },
                  expression: "tablesearch"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-card-subtitle", [
                _vm._v(
                  _vm._s(
                    _vm.$t("notifications.notifications-listing-inline-message")
                  )
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:severity",
                value: _vm.$t("common.severity"),
                expression: "$t('common.severity')",
                arg: "severity"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:description",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "description"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:date.hide-when-extra-small",
                value: _vm.$t("common.date"),
                expression: "$t('common.date')",
                arg: "date",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:read.hide-when-extra-small",
                value: _vm.$t("common.read"),
                expression: "$t('common.read')",
                arg: "read",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              }
            ],
            staticClass: "fd-actions-table fd-enabling-table fd-severity-table",
            attrs: {
              "custom-sort": _vm.customSort,
              items: _vm.notifications,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["date"],
              "sort-desc": true,
              "items-per-page": 5,
              "mobile-breakpoint": "0",
              "no-data-text": _vm.$t(
                "notifications.no-notifications-table-message"
              )
            },
            scopedSlots: _vm._u([
              {
                key: "item.read",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.isRead, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipUnread(item)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.severity",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.severity == 100
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  "padding-left": "10px",
                                                  "font-size": "2em"
                                                },
                                                attrs: {
                                                  color: "red",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("fa-exclamation-circle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("common.severity-critical-tooltip")
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.severity == 10
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  "padding-left": "15px",
                                                  "font-size": "1.2em"
                                                },
                                                attrs: {
                                                  color: "orange",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("fa-exclamation-triangle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("common.severity-warning-tooltip")
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.severity == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  "padding-left": "15px",
                                                  "font-size": "1.2em"
                                                },
                                                attrs: {
                                                  color: "primary",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("fa-info-circle")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "common.severity-information-tooltip"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.navigateFromNotification(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-eye\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.details")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }