var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ]
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-breadcrumbs", {
                            class: [
                              _vm.processing
                                ? "breadcrumb-processing-opacity"
                                : "",
                              "pl-3"
                            ],
                            attrs: {
                              items: _vm.$store.state.currentBreadcrumbs,
                              large: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "divider",
                                fn: function() {
                                  return [
                                    _c("v-icon", [_vm._v("fa-chevron-right")])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm.processing
                            ? _c("v-progress-circular", {
                                staticClass: "mr-3",
                                attrs: {
                                  indeterminate: true,
                                  rotate: 0,
                                  size: 32,
                                  width: 4,
                                  color: "white"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("fd-chip-selector", {
                        attrs: {
                          "available-items": _vm.availableTags,
                          "selected-items": _vm.selectedTags,
                          "item-label": "name",
                          disabled: _vm.processing
                        },
                        on: {
                          "update:selectedItems": function($event) {
                            _vm.selectedTags = $event
                          },
                          "update:selected-items": function($event) {
                            _vm.selectedTags = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("common.name"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.required]
                        },
                        model: {
                          value: _vm.mapping.name,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "name", $$v)
                          },
                          expression: "mapping.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("common.name"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.mapping.courseurl,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "courseurl", $$v)
                          },
                          expression: "mapping.courseurl"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("mappings.valid-for"),
                          disabled: _vm.processing,
                          rules: [_vm.rules.numbersonly]
                        },
                        model: {
                          value: _vm.mapping.validfor,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "validfor", _vm._n($$v))
                          },
                          expression: "mapping.validfor"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("mappings.course-url"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.mapping.courseurl,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "courseurl", $$v)
                          },
                          expression: "mapping.courseurl"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("mappings.course-description"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.mapping.coursedescription,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "coursedescription", $$v)
                          },
                          expression: "mapping.coursedescription"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: _vm.$t("common.enabled"),
                          disabled: _vm.processing
                        },
                        model: {
                          value: _vm.mapping.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.mapping, "enabled", $$v)
                          },
                          expression: "mapping.enabled"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mr-0",
                              attrs: { type: _vm.inlineMessage.type }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mr-0", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                link: "",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.saving,
                                disabled: _vm.processing
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }