var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "draggabletabletest" } },
    [
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "1.40rem !important",
                          position: "relative"
                        }
                      },
                      [_vm._v("\n            Draggable Table Test\n          ")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", { staticStyle: { "margin-top": "15px" } }, [
            _vm._v(
              "\n      Draggable - Required body template to be manually created\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
              {
                name: "fd-column",
                rawName: "v-fd-column:initialOrder.class_fd-action-cell",
                value: "Initial Order",
                expression: "'Initial Order'",
                arg: "initialOrder",
                modifiers: { "class_fd-action-cell": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:order.class_fd-action-cell",
                value: "Order",
                expression: "'Order'",
                arg: "order",
                modifiers: { "class_fd-action-cell": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:fd-drag-handle.no-sort.class_fd-drag-handle",
                value: "",
                expression: "''",
                arg: "fd-drag-handle",
                modifiers: { "no-sort": true, "class_fd-drag-handle": true }
              }
            ],
            ref: "datatable1",
            attrs: {
              items: _vm.sortedItems,
              "disable-pagination": true,
              "hide-default-footer": true,
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function(props) {
                  return [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          attrs: { list: props.items, tag: "tbody" },
                          on: { end: _vm.dragEnded }
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      _vm._l(props.items, function(item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          _c("td", { staticClass: "fd-action-cell" }, [
                            _vm._v(_vm._s(item.initialOrder))
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "fd-action-cell" }, [
                            _vm._v(_vm._s(item.order))
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "fd-drag-handle" },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("far fa-grip-lines")
                              ])
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("v-card-subtitle", { staticStyle: { "margin-top": "15px" } }, [
            _vm._v(
              "\n      Sortable - Doesn't use vuedraggable, and requires script code but table is generated as\n      normal\n    "
            )
          ]),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
              {
                name: "fd-column",
                rawName: "v-fd-column:initialOrder.class_fd-action-cell",
                value: "Initial Order",
                expression: "'Initial Order'",
                arg: "initialOrder",
                modifiers: { "class_fd-action-cell": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:order.class_fd-action-cell",
                value: "Order",
                expression: "'Order'",
                arg: "order",
                modifiers: { "class_fd-action-cell": true }
              },
              {
                name: "fd-table-sortable",
                rawName: "v-fd-table-sortable:order",
                value: Object.assign({}, _vm.dragOptions),
                expression: "{ ...dragOptions }",
                arg: "order"
              }
            ],
            ref: "datatable2",
            attrs: {
              items: _vm.items,
              "disable-pagination": true,
              "hide-default-footer": true,
              "item-class": function(item) {
                return "initial-" + item.initialOrder + " item"
              },
              "mobile-breakpoint": "0"
            },
            on: { "sort:update": _vm.dragEnded2 },
            scopedSlots: _vm._u([
              {
                key: "item.fd-drag",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("far fa-grip-lines")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }